import styled from 'styled-components';

export const UiClickablePopoverMenu = styled.div`
	display: inline-flex;
	width: ${({ width }) => width ? width : 'auto'};
	border-radius: 50%;
	transition: 0.35s;

	& > svg {
		cursor: pointer;
	}

	&:hover {
		background-color: #fafafc;
	}
`;
