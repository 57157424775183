import React from 'react';

import { SingleLayout, LoginForm } from 'components';
import { UiLoginBox } from './LoginPage.style';


/**
 * 在這邊描述這個組件
 */

export const LoginPage = () => {
  return (
    <SingleLayout>
      <UiLoginBox>
        <LoginForm />
      </UiLoginBox>
    </SingleLayout>
  );
};

