import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { EDIT_STATUS } from 'constants/index';
import { Table, IconInput, Select, Button, DatePicker, Icon, Modal, BsModal } from 'components';
import { useClass } from 'store/class';
import { useSetState } from 'utils/hooks/useSetState';
import { useAlert } from 'utils/hooks/useAlert';

import { UiHiddenInput, UiInviteClassCode, UiInviteClassCodeDate, UiSvg } from './ClassTable.style';
import { UiTable, UiActionBox, UiflexBox, UiTableButtonBox, UiEmphasize } from 'styles';


/**
 * 班級列表
 */
const schema = {
  name: {
    name: '班級名稱',
    defaultValue: ''
  },
  createDate: {
    name: '建班時間',
    defaultValue: ''
  },
  expirationTime: {
    name: '有效時間',
    defaultValue: ''
  },
  isExpired: {
    name: '班級狀態',
    defaultValue: ''
  },
  ownerName: {
    name: '導師',
    defaultValue: ''
  }
};

const selectInputOptions = [
  {
    name: '搜尋導師暱稱',
    value: 'ownerName'
  },
  {
    name: '搜尋導師手機號碼',
    value: 'ownerMobileNumber'
  }
];

const expiredOptions = [
  {
    name: '全部',
    value: ''
  },
  {
    name: '未到期',
    value: 'false'
  },
  {
    name: '已到期',
    value: 'true'
  }
];

const expiredShowText = [
  {
    id: 'isExpired',
    state: {
      false: '未到期',
      true: '已到期',
    }
  }
];

export const ClassTable = () => {
  const { setAlert } = useAlert();
  const history = useHistory();
  const { organizationId } = useParams();
  const [{ classes }, { getClasses, removeClassInfo, getClassInviteCode }] = useClass();
  const { data: classData, total: classTotalPage, dataMap: classDataMap } = classes;
  const [
    { goal,
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
      deleteModalState,
      actionClassId,
      isLoading,
      isOpen,
      inviteClassCode, // 代碼
      inviteClassDate, // 代碼到期時間
      isInvitationCodeExpired, // 代碼是否過期
      cancelDisplay,
    }, setState] = useSetState({
      goal: '',
      nowPage: 0,
      rowsPage: 10,
      name: '',
      ownerName: '',
      ownerMobileNumber: '',
      createdAfter: null,
      status: '',
      expired: '',
      deleteModalState: false,
      actionClassId: null,
      isLoading: false,
      isOpen: false,
      inviteClassCode: null,
      inviteClassDate: null,
      isInvitationCodeExpired: null,
      cancelDisplay: true,
    });

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const dateHandler = value => {
    setState({
      createdAfter: value,
      nowPage: 0
    });
  };

  // table 操作
  const ActionComponents = ({ params: { id, invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired } }) => {
    const goPage = editStatus => () => history.push(`/organization/${organizationId}/class/${id}/${editStatus}`);

    return (
      <UiTableButtonBox>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/session`)}
        >
          課程管理
        </Button>
        <Button
          onClick={() => history.push(`/organization/${organizationId}/class/${id}/customer`)}
        >
          成員管理
        </Button>
        <Button
          onClick={() => {
            modalToggle();
            setInvitationCode({invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired, id});
          }}
        >
          邀請代碼
        </Button>
        <Icon name="edit" title="編輯班級" onClick={goPage(EDIT_STATUS.EDIT)} />
        <Icon
          name="delete"
          title="刪除班級"
          type='danger'
          onClick={() => setState({ deleteModalState: true, actionClassId: id })}
        />
      </UiTableButtonBox>
    );
  };

  const setInvitationCode = ({invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired, id}) => {
    setState({
      inviteClassCode: invitationCode,
      inviteClassDate: invitationCodeExpiredAt && format(new Date(invitationCodeExpiredAt), 'MM月dd日 HH:mm'),
      isInvitationCodeExpired,
      actionClassId: id || actionClassId,
    });
  };

  const fetchGetClasses = async () => {
    await getClasses({
      nowPage,
      rowsPage,
      name,
      ownerName,
      ownerMobileNumber,
      createdAfter,
      status,
      expired,
    });
  };

  const onSubmitHandler = (value, key) => {
    if (key === 'condition') {
      if (goal === '') {
        setAlert('請選擇搜尋目標!', 'wranning');
        return;
      } else {
        setState({
          ownerName: '',
          ownerMobileNumber: '',
          [goal]: value,
          nowPage: 0
        });
      }
    }
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const setDeleteModalStateHandler = state => {
    setState({ deleteModalState: state });
  };

  const buttons = [
    {
      text: '確認',
      func: async () => {
        await removeClassInfo(actionClassId);
        await fetchGetClasses();
        setDeleteModalStateHandler(false);
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  // 複製代碼
  const codeRef = useRef();
  const onCopyCode = () => {
    const copyText = codeRef.current;
    copyText.select(); // 選擇物件
    document.execCommand('Copy'); // 執行瀏覽器複製命令
    setAlert('複製成功!', 'success');  // 跳成功訊息
  };

  const getInviteClassCodeHandler = async () => {
    setState({ isLoading: true });
    const data  = await getClassInviteCode(actionClassId);
    const { code:invitationCode, expiredAt:invitationCodeExpiredAt } = data;
    setInvitationCode({invitationCode, invitationCodeExpiredAt, isInvitationCodeExpired: false });
    await fetchGetClasses();
    setState({ isLoading: false, isInvitationCodeExpired: false });
  };

  const onModalOk = () => {
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {
        // 生成代碼
        getInviteClassCodeHandler();
      } else {
        //  關閉視窗
        modalToggle();
      }
    } else {
      // 生成代碼
      getInviteClassCodeHandler();
    }
  };

  const modalToggle = () => {
    setState({
      isOpen : !isOpen,
    });
  };

  const onOkTextHandle = () => {
    let text = null;
    if(inviteClassCode) {
      // 判斷過期
      if(isInvitationCodeExpired) {
        text = '產生';

      } else {
        text = '關閉';
      }
    } else {
        text = '產生';
    }
    return text;
  };

  useEffect(() => {
    if(inviteClassCode) {
      setState({
        cancelDisplay: false,
      });
    } else {
      setState({
        cancelDisplay: true,
      });
    }
  },[inviteClassCode]);

  useEffect(() => {
    fetchGetClasses();
  }, [nowPage, rowsPage, name, ownerName, ownerMobileNumber, createdAfter, status, expired]);

  // eslint-disable-next-line react/prop-types
  const ModalText = ({ name }) => (
      <div>
        您確定要將 <UiEmphasize>{name}</UiEmphasize> 刪除嗎?這樣班級中的課程將會消失、班級成員也都無法再回到這個班級喔!!
      </div>
    );

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <IconInput
            placeholder='搜尋班級名稱'
            onChange={value => onSubmitHandler(value, 'name')}
          />
          <Select
            label="班級狀態"
            options={expiredOptions}
            submitHandler={value => onSubmitHandler(value, 'expired')}
          />
          <DatePicker
            label="搜尋建班時間"
            value={createdAfter}
            onChange={dateHandler}
          />
          <Select
            label="搜尋目標"
            options={selectInputOptions}
            submitHandler={value => onSubmitHandler(value, 'goal')}
          />
          {
            goal && <IconInput
              placeholder='搜尋條件'
              onChange={value => onSubmitHandler(value, 'condition')}
            />
          }
        </UiflexBox>
        <UiflexBox>
          <Button
            buttonColor='highlight'
            icon='add'
            onClick={() => history.push(`/organization/${organizationId}/class/create`)}>新增班級</Button>
        </UiflexBox>
      </UiActionBox>
      <Table
        data={
          classData.map(item => {
            item.createDate = format(new Date(item.createdAt), 'yyyy-MM-dd');
            item.expirationTime = format(new Date(item.expirationTime), 'yyyy-MM-dd');
            return item;
          })
        }
        schema={schema}
        stateShowText={expiredShowText}
        changePage_Rows={changePage_Rows}
        totalPage={classTotalPage}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />

      <Modal
        isOpen={deleteModalState}
        content={<ModalText name={classDataMap[actionClassId] && classDataMap[actionClassId].name}/>}
        getModalState={state => setDeleteModalStateHandler(state)}
        buttons={buttons}
      />

      <BsModal
       open={isOpen}
       title="邀請代碼"
       onOk={onModalOk}
       onCancel={modalToggle}
       okText={onOkTextHandle()}
       cancelDisplay={cancelDisplay}
       isLoading={isLoading}
      >
        {
          inviteClassCode ?
            isInvitationCodeExpired ? <div>邀請代碼已過期，是否要產生邀請代碼?</div>
              : <>
                <UiInviteClassCode>
                  <div>透過邀請代碼可加入班級：</div>
                  <div>{inviteClassCode}</div>
                  <UiSvg onClick={onCopyCode}>
                    <Icon.Svg name="Copy" />
                  </UiSvg>
                </UiInviteClassCode>
                <UiInviteClassCodeDate>班級代碼過期日期：{inviteClassDate}</UiInviteClassCodeDate>
                <UiHiddenInput>
                  <input type="text" ref={codeRef} value={inviteClassCode} />
                </UiHiddenInput>
              </>
            : <div>目前沒有邀請代碼，是否要產生邀請代碼?</div>
        }
      </BsModal>
    </UiTable>);
};



