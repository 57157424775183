import styled from 'styled-components';

export const UiHomeGroupLayout = styled.div`
display: flex;
min-height: 100vh;
background-color: #fff;
flex-direction: column;
`;

export const UiChildrenContainer = styled.div`
padding: 0 1rem;
width: calc(100% - 200px);
`;

export const UiHomeGroupContent = styled.div`
	display: flex;
	margin: auto;
	padding: ${({ showSideBar }) => showSideBar ? '4rem 0 2rem' : '0 0 2rem'};
	width: 95%;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	flex: 1;
	${({ theme: { media } }) => media.desktop`
      width:${({ theme }) => theme.mediaWidth.desktop}
      `}
	${({ theme: { media } }) => media.notebook`
      width:${({ theme }) => theme.mediaWidth.notebook}
      `}
	${({ theme: { media } }) => media.pad`
      width:${({ theme }) => theme.mediaWidth.pad}
      `}
	${({ theme: { media } }) => media.phone`
      width:${({ theme }) => theme.mediaWidth.phone}
      `}
`;


