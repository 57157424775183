import styled from 'styled-components';

export const UiHomeworkCardEdit = styled.div`
	display: block;
`;

export const UiCardHeader = styled.div`
	display: block;
	margin: 0 1.5rem;
	font-size: 2rem;
	border-bottom: 2px solid #ccc;
`;
