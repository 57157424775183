import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ResourceModalContent from './ResourceModalContent';

import { Button, Modal, } from 'components';
import { EDIT_STATUS } from 'constants/index';
import { getSessionResources as getSessionResourcesApi } from 'services/api/organization/session';
import { useParams } from 'react-router-dom';
import {
  UiResourceModal,
  UiButtonContainer,
  UiResourcesContainer,
  UiMatFormHelperText,
} from './SessionFormResourceModal.style';

export const SessionFormResourceModal = ({
  buttonName,
  editStatus,
  error,
  hostId,
  title,
  onChange,
}) => {
  const { organizationId, sessionId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);  // 彈窗是否開啟
  const [isCheckedCheckboxFull, setIsCheckedCheckboxFull] = useState(false); // 勾選的 checkbox 是否已滿
  const [checkedCheckbox, setCheckedCheckbox] = useState([]);       // 勾選的 checkbox 內容 {id, name, isChecked}
  const [checkedCheckboxPre, setCheckedCheckboxPre] = useState([]); // 前一次勾選的 checkbox 內容

  /* 關閉 modal */
  const getModalStateHandler = (state) => {
    setCheckedCheckbox(checkedCheckboxPre); // 把原本的勾選內容復原
    setIsModalOpen(state);
  };
  const clickCloseHandler = () => {
    setCheckedCheckbox(checkedCheckboxPre); // 把原本的勾選內容復原
    setIsModalOpen(false);
  };

  /* checkbox 取消勾選 */
  const changeUncheckCheckboxHandler = (resourceId) => {
    setCheckedCheckbox(checkedCheckbox.filter(el => el.id !== resourceId));
  };

  /* change checkbox 勾選狀況 */
  const changeCheckboxHandler = (e) => {
    let newCheckedCheckbox = [ ...checkedCheckbox ];
    const id = e.target.id;
    const name = e.target.name;
    const isChecked = e.target.checked;

    // 取消勾選
    if(newCheckedCheckbox.some(obj => obj.id === id)){
      changeUncheckCheckboxHandler(id);
    }
    // 勾選
    else{
      // 選項未滿
      if(checkedCheckbox.length < 6) {
        newCheckedCheckbox.push({ id, name, isChecked });
        setCheckedCheckbox(newCheckedCheckbox);
      }
      // 選項已滿
      else if(checkedCheckbox.length === 6){
        setIsCheckedCheckboxFull(true);
        setTimeout(() => 
          setIsCheckedCheckboxFull(false)
        ,[1500]);
      }
    }
  };

  /* click 確定，傳回至 SessionForm.js schema -> resourceIdList -> onChange() */
  const clickSubmitHandler = () => {
    // Array: checkedCheckbox.id
    const resourceIdList = checkedCheckbox.map(checkbox => {
      return checkbox.id;
    });
    setCheckedCheckboxPre(checkedCheckbox); // 把當前的勾選的內容設定至前一次狀態
    setIsModalOpen(false);
    onChange(resourceIdList); //傳回至 SessionForm.js
  };

  /* 打API，取得教材資訊 */
  const fetchSessionInfo = async () => {
    const { isSuccess, data } = await getSessionResourcesApi(organizationId, sessionId);

    if(isSuccess){
      const newCheckedCheckbox = data.map(resource => {
        const id = resource.resourceId;
        const name = resource.displayName;
        const isChecked = true;
        return {
          id,
          name,
          isChecked
        };
      });
      setCheckedCheckbox(newCheckedCheckbox);
      setCheckedCheckboxPre(newCheckedCheckbox);
    }
  };

  useEffect(() => {
    // 編輯課程
    if(sessionId && editStatus === EDIT_STATUS.EDIT) fetchSessionInfo();
    
    // 把當前的勾選的內容設定至前一次狀態
    setCheckedCheckboxPre(checkedCheckbox);
  }, []);

  return (
    <UiResourceModal>
      {/* 選擇教學檔案 - 按鈕 */}
      <UiButtonContainer>
        <Button
          onClick={()=>{setIsModalOpen(true);}}>
          {buttonName}
        </Button>
      </UiButtonContainer>

      {/* 選擇教學檔案 - 按鈕旁資源列表 */}
      <UiResourcesContainer>
        {
          (error && checkedCheckbox.length === 0) && (
            <UiMatFormHelperText >
              請選擇教學資源
            </UiMatFormHelperText>)
        }
        {
          (checkedCheckbox === checkedCheckboxPre) &&  checkedCheckbox.map(checkbox => 
            <span key={checkbox.id}>
              {checkbox.name}(ID:{checkbox.id})
            </span>
          )
        }
      </UiResourcesContainer>

      {/* 選擇教學檔案 - 彈窗 */}
      <Modal
        size='large'
        isIconShow={false}
        isOpen={isModalOpen}
        title={title}
        getModalState={getModalStateHandler}
        content={
          <ResourceModalContent
            checkedCheckbox={checkedCheckbox}
            editStatus={editStatus}
            hostId={hostId}
            isCheckedCheckboxFull={isCheckedCheckboxFull}
            onChangeCheckbox={changeCheckboxHandler}
            onChangeUncheckCheckbox={changeUncheckCheckboxHandler}
            onClickClose={clickCloseHandler}
            onClickSubmit={clickSubmitHandler} />
        }
      />
    </UiResourceModal>
  );
};

SessionFormResourceModal.propTypes = {
  buttonName: PropTypes.string,
  editStatus: PropTypes.string,
  error: PropTypes.string,
  hostId: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};