import styled from 'styled-components';
import { Avatar as MatAvatar,GridListTile as MatGridListTile,GridList as MatGridList } from '@material-ui/core';
export const UiPostCardAll = styled.div`
	display: flex;
	width: 100%;
`;

export const UiAvatar = styled(MatAvatar)`
	margin: ${({ isSecret }) => isSecret ? '0.5rem 0.5rem 0 0 ' : '0'};
	width: 3rem;
	height: 3rem;
`;
export const UiPostCardHead = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const UiPostCard = styled.div`
	position: relative;
	width: 100%;
	color: #163e6f;
`;
export const UiIsRead = styled.div`
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;

	& > svg {
		font-size: 1.4rem;
	}
`;

export const UiTeacherInfo = styled.div`

`;

export const UiUserName = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
`;

export const UiUserBox = styled.div`
	display: flex;
	align-items: center;
	padding: 0.5rem 0.75rem;
	min-width: 100px;
	height: min-content;
	margin-bottom: 0.5rem;

	& > .flexBox {
		display: flex;
		align-items: center;
		flex: 1;
	}
`;
export const UiTime = styled.div`
	font-size: 0.75rem;
	color: #777;
`;

export const UiUser = styled.div`
	padding: 0 0.5rem;
	font-size: 1.25rem;
	color: #004b62;
`;
export const UiContentBox = styled.div`
	padding: 0.5rem 0.75rem;
	color: #163e6f;
	border-bottom: 1px solid #d0e0e2;
`;

export const UiContentText = styled.div`
	overflow: hidden;
	font-size: 1rem;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #3c4858;
	margin-bottom: 2rem;
	word-wrap: break-word;
`;

export const UiMore = styled.div`
	padding: 5px 0 15px;
	width: 100px;
	font-size: 1.2rem;
	font-weight: bold;
	cursor: pointer;
`;

export const UiContent = styled.div`
	font-size: 1rem;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #3c4858;
`;

export const UiCardFooter = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 0.25rem;

	&::after {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 50%;
		left: 50%;
		width: 1px;
		height: 80%;
		background-color: #d0e0e2;
		content: '';
	}

	& > div {
		margin-left: ${({ isSecret }) => isSecret ? 'auto' : 'unset'};
	}

	& + div {
		border-top:
			${({ theme }) =>
			`1px solid ${theme.oneClass.border.palette.default}`};
	}

`;

export const LabelGroupAll = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding: 0.5rem;
	width: 48%;
	border-radius: 5px;
	transition: 0.35s;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: #fafafc;
	}
`;
export const LabelGroup = styled.div`
	display: flex;
	align-items: center;

	/* min-width: ${({ userType }) => userType === 'teacher' ? '35%' : 'unset'}; */
	color: ${({ isLikeClick }) => isLikeClick ? '#00828F' : '#a8abae'};
	cursor: pointer;

	& > svg {
		margin-right: 0.25rem;
		font-size: 1.5rem;
		color: ${({ isLikeClick }) => isLikeClick ? '#00828F' : '#a8abae'};
	}

	& + div {
		margin-left: 2rem;
	}
`;

export const Label = styled.div`
	margin-right: 0.5rem;
	font-weight: bold;
`;

export const LabelValue = styled.div`
`;
export const UiChangeButtonBox = styled.div`
	button {
		margin: 8px 8px 8px 0;
	}
`;
export const UiStudentHomeworkForm = styled.div`
	form {
		padding: 0;

		& > div > div > button {
			margin: 8px 8px 8px 0;
		}
	}
`;


export const UiInfo = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem 0.75rem;
	border-bottom:
		${({ theme }) =>
		`1px solid ${theme.oneClass.border.palette.default}`};
	color: #004b62;

	& > div {
		display: flex;
		align-items: center;
		letter-spacing: 1px;

		& > span {
			padding: 0 0.5rem;
			letter-spacing: 2px;
		}
	}
`;

export const UiCircleIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.2rem;
	width: 25px;
	height: 25px;
	border-radius: 50%;

	& > svg {
		font-size: 1.4rem;
		color: #39c5bb;
	}
`;

export const UiMessage = styled.div`
	display: flex;
	padding: 1rem 0.5rem;
	flex-direction: column-reverse;
`;

export const UiMessageItem = styled.div`
	display: flex;
	align-items: center;

	& + div {
		margin-top: 0.8rem;
	}

	& > div:nth-child(1) {
		margin-right: 1rem;
	}
`;

export const UiAttachmentBox = styled.div`
	a {
		padding: 0.5rem 1rem !important;
		width: 100% !important;
		height: auto !important;
		font-size: 1rem;
		text-decoration: none;
		color: ${({ theme }) => theme.palette.text.normal};
		background-color: ${({ theme }) => theme.palette.background.main};
	}

	video {
		width: 100% !important;
		height: 400px !important;
		border: none;
	}
`;



export const UiReplie = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	.inputBox {
		width: calc(100% - 6rem);
	}

	.cancel {
		padding: 2px 0 5px 4rem;

		span {
			color: #e55;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	& + div {
		margin-bottom: 1rem;
	}

	& > div:nth-child(1) {
		margin-right: 1rem;
	}
`;

export const UiReplieBox = styled.div`
	position: relative;
	width: 100%;
	max-width: 420px;
`;

export const UiReplieContent = styled.div`
	padding: 8px 12px 20px;
	width: 100%;
	min-height: 50px;
	background-color: ${({ theme }) => theme.palette.background.main};
	border: 1px solid  ${({ theme }) => theme.palette.line.light};
	border-radius: 5px;
	margin-bottom: 5px;

	span:nth-child(1) {
		display: block;
		margin-right: 5px;
		color: ${({ theme }) => theme.palette.text.active};
		margin-bottom: 5px;
	}

	& > div > img {
		margin-top: 10px;
		max-width: 100%;
	}

	& > div > a {
		color: ${({ theme }) => theme.palette.text.sub};
	}
`;

export const UiAwesomeSpan = styled.div`
	cursor: pointer;
	transition: 0.35s;

	&:hover {
		opacity: 0.8;
	}
`;
export const UiAwesomeBox = styled.div`
	position: absolute;
	bottom: 12.5px;
	right: 10px;
	display: flex;
	align-items: center;
	padding: 0 3px;
	color: #606770;
	background-color: #a9cff2;
	border-radius: 50px;

	& > svg {
		margin-right: 5px;
		font-size: 1rem;
	}

	& > span {
		margin-right: 5px;
		font-size: 10px;
		color: #606770;
	}
`;

export const UiReplieMenu = styled.div`
	& > div {
		padding: 0.5rem 0 0 0.5rem;

		& > svg {
			font-size: 1.2rem;
		}
	}
`;

export const UiPrevMessage = styled.div`
	margin-bottom: 1rem;
	cursor: pointer;
`;


export const UiGridListTile = styled(MatGridListTile)`
	position: relative;

	img {
		cursor: pointer;
	}

	&:hover img {
		opacity: 0.85;
	}
`;

export const UiOnePicture = styled.div`
	width: 100% !important;
	height: 400px !important;
	font-size: 0;

	img {
		cursor: pointer;
		object-fit: scale-down;
		width: 100%;
		height: 100%;
		transition: 0.35s;

		&:hover {
			opacity: 0.85;
		}
	}
`;

export const UiImageBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-size: 2.5rem;
	font-weight: bold;
	color: #ccc;
	background-color: rgba(0, 0, 0, 0.5);
	transition: 0.35s;

	&:hover {
		color: #fff;
	}
`;


export const UiGridList = styled(MatGridList)`
	width: 100%;
`;
