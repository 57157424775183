import styled from 'styled-components';

export const UiGroupsCard = styled.div`
	margin: 3rem 0 4rem;
`;

export const UiGroupsHead = styled.div`
display: flex;
align-items: center;
padding: 0.5rem 1rem;
background-color:
	${(
		{ theme,
		type }
	) => type === 'school' ?
	theme.palette.block.light :
	theme.palette.user.block.bar};
margin-bottom: 1rem;
border-radius: 5px;

& > span {
	font-size: 1.5rem;
	font-weight: bold;
	color: ${({ theme }) => theme.palette.primary.sec};
}

& > button {
	margin-left: 1.5rem;
}

`;

export const UiGroupsBox = styled.div`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 0 1rem;

	& > div {
		margin-bottom: 20px;
	}

	& > div:not(:nth-child(5n + 1)) {
		margin-left: 2.5%;
	}
`;

export const UiGroupItem = styled.div`
	position: relative;
	overflow: hidden;
	width: 18%;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	transition: 0.35s;
	cursor: pointer;

	& > div {
		padding: 15px;
		color: ${({ theme }) => theme.palette.primary.sec};
		letter-spacing: 2px;
		line-height: 1.5;

		& > div:nth-child(1) {
			font-size: 1rem;
			opacity: 0.8;
		}

		& > div:nth-child(2) {
			font-size: 1.2rem;
		}
	}

	&:hover {
		box-shadow: 0 6px 7px rgba(0, 0, 0, 0.2), 0 5px 6px rgba(0, 0, 0, 0.17);
	}
`;

export const UiGroupsImage = styled.div`
	width: 100%;
	height: 200px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #eee;
	background-image: ${(props) => `url(${props.url}) `};
`;

export const UiGroupsPrompt = styled.div`
	margin: 0.25rem 0 1rem;
	padding: 15px;
	width: 100%;
	font-size: 1.3rem;
	color: #f00;
`;

export const UiRoleIcon = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	padding: 0 !important;
`;
