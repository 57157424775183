import styled from 'styled-components';

export const UiSingleLayout = styled.div`
	display: flex;
	min-height: 100vh;
	background-color: #fff;
	flex-direction: column;
`;

export const UiSingleContent = styled.div`
	flex: 1;
	margin: auto;
	width: 95%;
	font-family: ${({theme})=>theme.typography.fontFamily};
	${({ theme: { media } }) => media.desktop`
		width:${({ theme }) => theme.mediaWidth.desktop}
		`}
	${({ theme: { media } }) => media.notebook`
		width:${({ theme }) => theme.mediaWidth.notebook}
		`}
	${({ theme: { media } }) => media.pad`
		width:${({ theme }) => theme.mediaWidth.pad}
		`}
	${({ theme: { media } }) => media.phone`
		width:${({ theme }) => theme.mediaWidth.phone}
		`}
`;
