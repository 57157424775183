import styled from 'styled-components';
import {
  Dialog as MatDialog,
  TextareaAutosize as MatTextareaAutosize,
  DialogContent as MatDialogContent,
  DialogContentText as MatDialogContentText,
  DialogActions as MatDialogActions
 } from '@material-ui/core';

export const UiPostModal = styled(MatDialog)`
	.MuiPaper-elevation24 {
		position: relative;
		overflow: hidden;
		padding: 1rem;
		width: 400px;
		background-color: #fff;
	}
`;

export const UiPostTitle = styled.div`
	text-align: center;
	margin-bottom: 1.5rem;
`;

export const UiPostFlexBox = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const UiCloseButton = styled.div`
	position: absolute;
	top: 1rem;
	right: 1rem;
	padding: 6px 7px 3px;
	border: 1px solid #aaa;
	border-radius: 99px;
	transition: 0.35s;
	cursor: pointer;

	svg {
		font-size: 1.2rem;
		color: #888;
	}

	&:hover {
		background-color: #aaa;
	}

	&:hover svg {
		color: #fff;
	}
`;

export const UiUserInfo = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0;

	.MuiAvatar-root {
		margin-right: 5px;
	}

	span {
		font-size: 0.9rem;
		font-weight: bold;
		color: ${({ theme }) => theme.palette.main.success};
	}
`;

export const UiTextarea = styled(MatTextareaAutosize)`
	overflow-y: scroll !important;
	padding-top: ${({ isOnlyImage }) => isOnlyImage && '1rem'};
	min-height: 150px;
	border: none;
	outline: none;
	resize: none;
	margin-bottom: 1rem;
`;

export const UiFileBox = styled.div`
	display: -webkit-box;
	margin-bottom: 1rem;
	overflow-y: hidden;
`;

export const UiImageItem = styled.div`
	position: relative;
	margin-right: 5px;
	width: 100px;
	height: 100px;
	font-size: 0;

	img {
		max-width: 100%;
		object-fit: contain;
		object-position: 50% 50%;
	}

	svg {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 1.4rem;
		cursor: pointer;
		opacity: 0.7;
		transition: 0.35s;

		&:hover {
			opacity: 0.9;
		}
	}
`;

export const UiFileItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem;
	width: 100%;
	background-color: #eee;
	border: 1px solid ${({ theme }) => theme.palette.line.light};
	border-radius: 5px;

	& > svg {
		cursor: pointer;
		font-size: 1.2rem;
	}
`;

export const UiAddFileItem = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	font-size: 2rem;
	border: 1px dashed #777;
	cursor: pointer;

	& > input {
		display: none;
	}
`;

export const UiUpdateFileBox = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.5rem;
	border: 1px solid ${({ theme }) => theme.palette.btn.NormalHover};
	border-radius: 5px;
	margin-bottom: 1rem;

	span {
		display: inline-block;
		width: 100%;
		line-height: 2;
		color: ${({ theme }) => theme.palette.text.Placeholder};
	}

	svg {
		margin: 0 0.25rem;
		font-size: 1.3rem;
		color: ${({ theme }) => theme.palette.btn.NormalHover};
		cursor: pointer;
	}

	input {
		display: none;
	}
`;

export const UiUpdateFileItem = styled.label`
	display: block;
`;

export const UiDialogContent = styled(MatDialogContent)`
	padding: 0.8rem 8rem;
`;

export const UiDialogContentText = styled(MatDialogContentText)`
	font-size: 1.8rem;
	text-align: center;
	color: #333;
`;

export const UiDialogActions = styled(MatDialogActions)`
	justify-content: center;
	padding: 1.5rem;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
`;


