import React from 'react';
import PropTypes from 'prop-types';
import { 
  UiOptionMenu,
  UiOption,
} from './OptionMenu.style';


/**
 * 更多按鈕的收納選單
 */

 export const OptionMenu = ({ data }) => {
  return (
    <UiOptionMenu>
      {data.map((value, index) => {
        const { label, onClick } = value;
        return (
          <UiOption key={index} onClick={onClick}>
            {label}
          </UiOption>
        );
      })}
    </UiOptionMenu>
  );
};

OptionMenu.propTypes = {
  data: PropTypes.array,
};


