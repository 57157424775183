import styled from 'styled-components';

export const UiPictureBox = styled.div`
	display: block;
	margin: 2%;
	min-width: 140px;
	max-width: 100%;
	height: 200px;
	background: url("${({ path }) => path}");
	background-position: center;
	background-size: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;
