import styled from 'styled-components';

const cardTypeColor = {
  draft: 'red',
  pinned: '#fff'
};

export const UiCardType = styled.div`
  display: inline-flex;
  align-items: center;
	margin-right: 1rem;
	padding: 0.25rem 1.5rem;
	color: ${({ type }) => cardTypeColor[type] || '#f9a82f'};
	background-color: ${({ type }) => type === 'pinned' ? '#f9a82f' : '#dfeeff'};
	border-radius: 50px;
  font-size: 12px;
  letter-spacing: 0.05em;
`;

export const UiAnnouncementCardHead = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const UiAnnouncementCardRead = styled.div`
	width: 100%;
	color: #fff;

`;
export const UiTeacherInfo = styled.div`

`;

export const UiUserName = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
`;

export const UiCreateDateBox = styled.div`
margin-right: 1rem;
min-width: 100px;
height: min-content;
text-align: center;
color: #586f7d;
background-color: #ace5ed;
border: 1px solid #d0e0e2;
`;
export const UiCreateWeek = styled.div`
padding: 0.5rem 0;
color: #3b767d;
background-color: #39c5bb;
`;

export const UiCreateDateAll = styled.div`
	margin: 1rem 0;
	font-size: 1.2rem;
`;

export const UiCreateDate = styled.div`
`;
export const UiContentBox = styled.div`
color: #fff;
`;

export const UiContentTitle = styled.div`
  display: flex;
  align-items: center;
	padding: 0.25rem 0;
	font-size: 1.6rem;
	font-weight: bolder;
	color: #eed24f;

  > ${UiCardType} {
    margin-left: 1.5rem;
  }
`;

export const UiContent = styled.div`
	padding: 0.25rem 0;
`;

export const UiCardFooter = styled.div`
padding: 0.5rem 0.75rem;
`;


export const LabelGroup = styled.div`
	display: flex;
	margin-right: 1rem;
	min-width: ${({ userType }) => userType === 'teacher' ? '35%' : 'unset'};
`;

export const Label = styled.div`
	margin-right: 0.5rem;
	font-weight: bold;
`;

export const LabelValue = styled.div`
`;
export const UiChangeButtonBox = styled.div`
	button {
		margin: 8px 8px 8px 0;
	}
`;
export const UiStudentHomeworkForm = styled.div`
	form {
		padding: 0;

		& > div > div > button {
			margin: 8px 8px 8px 0;
		}
	}
`;

export const UiCardHeader = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 0 0.75rem;
	padding: 0.5rem 0;
	border-bottom: 1px solid #d0e0e2;
`;

export const UiCardHeaderRow = styled.div`
display: flex;
align-items: flex-start;
`;

export const UiCardContent = styled.div`
	padding: 0.5rem 0.75rem;
`;
export const UiCardFooterRow = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 0.5rem;
`;
export const UiCardFooterBox = styled.div`
	position: relative;
	padding: 0.5rem;
	width: 50%;
	min-width: 300px;
	color: #fff;
	background-color: rgba(41, 140, 140, 0.5);
	border: 1px solid #fff;

	&:first-child {
		margin-right: 1rem;
	}
`;
export const UiCardFooterBoxTitle = styled.div`
	color: #83ced5;
`;
export const UiCardFooterFileBox = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	width: 50%;
	min-width: 300px;
	color: #fff;
	background-color: rgba(41, 140, 140, 0.5);
	border: 1px solid #fff;
`;

export const UiCardFooterFileBoxLabel = styled.div`
	display: flex;
`;


export const UiCheckBlock = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
`;
