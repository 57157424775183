import styled from 'styled-components';

export const UiCard = styled.div`
	position: relative;
	width: 100%;
	background-color: ${({ backgroundColor }) => backgroundColor};
	border:
		${({ theme }) =>
		`1px solid ${theme.oneClass.border.palette.default}`};
	border-radius: 5px;
	margin-bottom: 1rem;
`;

export const UiCardContent = styled.div`
`;
