import React, { Fragment,useEffect } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import {
  Drawer as MatDrawer,
  List as MatList,
  ListItemText as MatListItemText,
  TablePagination,
  Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getOwnerOrganizations as getOwnerOrganizationsApi } from 'services/api/organization/organizations';
import { useSetState } from 'utils/hooks/useSetState';
import { Icon, BsModal,IconInput } from 'components';
import { useUser } from 'store/user';
import { PERMISSIONS } from 'constants/index';
import {
  UiItem,
  UiItemCategory,
  UiCategoryHeader,
  UiOfficialName,
  UiLink,
  UiItemIcon,
  UiDivider,
  UiTable,
  UiTr,
  UiTd,
  UiTh,
  UiContent,
} from './BsNavigator.style';


/**
 * Backstage 側邊欄
 */

const checkPermission = (routes, permissions) => {
  return routes.map(item => {
    const checkedRoutes = item.children.filter(child => permissions.includes(child.permission));
    return { ...item, children: checkedRoutes };
  }).filter(item => item.children.length > 0);
};

const getCategories = ({ organizationId, myPermissions }) => {

  const ORGANIZATION_BASE_PATH = `/organization/${organizationId}`;


  const categories = [
    {
      id: '學務管理',
      children: [
        {
          id: '班級管理',
          icon: 'school',
          path: `${ORGANIZATION_BASE_PATH}/class`,
          permission: PERMISSIONS.VIEW_GROUP
        },
        {
          id: '教師管理',
          icon: 'person',
          path: `${ORGANIZATION_BASE_PATH}/teachers`,
          permission: PERMISSIONS.VIEW_STAFF_PROFILE
        },
        {
          id: '學生管理',
          icon: 'people',
          path: `${ORGANIZATION_BASE_PATH}/customer`,
          permission: PERMISSIONS.VIEW_CUSTOMER_PROFILE
        },
        {
          id: '課程總覽',
          icon: 'importContacts',
          path: `${ORGANIZATION_BASE_PATH}/class/session`,
          permission: PERMISSIONS.VIEW_GROUP
        }
      ],
    },
    {
      id: '資源管理',
      children: [
        {
          id: '資源列表',
          icon: 'viewList',
          path: `${ORGANIZATION_BASE_PATH}/resources`,
          permission: PERMISSIONS.VIEW_RESOURCE
        },
        {
          id: '取用權限',
          icon: 'settingsInputComponent',
          path: '',
          permission: PERMISSIONS.MANAGE_RESOURCE
        },
        {
          id: '看課備份',
          icon: 'backup',
          path: '',
          permission: PERMISSIONS.MANAGE_RESOURCE
        },
      ],
    },
    {
      id: '教務管理',
      children: [
        {
          id: '看課管理',
          icon: 'visibility',
          path: '',
          permission: PERMISSIONS.MONITOR_SESSION
        },
        // {
        //   id: '課堂報告',
        //   icon: 'poll',
        //   path: '',
        //   permission: PERMISSIONS.VIEW_SESSION_REPORT
        // },
      ],
    },
    {
      id: '機構管理',
      children: [
        {
          id: '機構設定',
          icon: 'settings',
          path: `${ORGANIZATION_BASE_PATH}/setting`,
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        },
        {
          id: '設定說明',
          icon: 'info',
          path: '',
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        },
        {
          id: '常用資源',
          icon: 'watchFile',
          path: `${ORGANIZATION_BASE_PATH}/webResource`,
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        },
      ],
    },
    {
      id: '權限管理',
      children: [
        {
          id: '權限設定',
          icon: 'tune',
          path: `${ORGANIZATION_BASE_PATH}/permissionSetting`,
          permission: PERMISSIONS.EDIT_ORGANIZATION_SETTING
        }
      ],
    }
  ];

  return checkPermission(categories, myPermissions);
};

export const BsNavigator = ({ ...otherProps }) => {

  // const history = useHistory();
  const [{
    isOpen,
    officialNameKeyword,
    nowPage,
    rowsPage,
    organizations,
    total,
  },setState] = useSetState({
    isOpen:false,
    officialNameKeyword:'',
    nowPage:0,
    rowsPage:10,
    organizations:[],
    total:0,
  });
  const { organizationId } = useParams();
  const history = useHistory();
  const [{
    myOrganization: { myPermissions, organization },
  }] = useUser();
  const goOtherOrg = id => {
    history.push(`/organization/${id}`);
    modalToggle();
  };

  const modalToggle = () => {
    setState({
      isOpen:!isOpen
    });
  };

  const onSubmitHandler = (value, key) => {
    setState({
      [key]: value,
      nowPage: 0
    });
  };

  const fetchOrganizations = async (payload) => {
    const { data:{ organizations,total } } = await getOwnerOrganizationsApi(payload);
    setState({
      organizations,
      total
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      nowPage:0,
      rowsPage:parseInt(event.target.value, 10),
    });
  };

  const handleChangePage = (event, newPage) => {
    setState({
      nowPage:newPage
    });
  };

  useEffect(()=>{
    const payload = {
      officialNameKeyword,
      nowPage,
      rowsPage
    };
    fetchOrganizations(payload);
  },[officialNameKeyword,nowPage,rowsPage]);

  return (
    <MatDrawer variant="permanent" {...otherProps}>
      <MatList disablePadding>
        <UiItemCategory>
          <UiOfficialName onClick={modalToggle}>
            {organization.officialName}
            {organization.officialName && <ExpandMoreIcon/> }
          </UiOfficialName>
        </UiItemCategory>
        {getCategories({ organizationId, myPermissions }).map(({ id, children }) => (
          <Fragment key={id}>
            <UiCategoryHeader>
              <MatListItemText>
                {id}
              </MatListItemText>
            </UiCategoryHeader>
            {children.map(({ id: childId, icon, path }) => (
              <UiItem
                key={childId}
                button
                disabled={!path}
              >
                <UiLink
                  to={path}
                  isActive={match => match}
                  activeClassName="active"
                  exact
                >
                  <UiItemIcon>
                    <Icon haveBg={false} name={icon}></Icon>
                  </UiItemIcon>
                  <MatListItemText>
                    {childId}
                  </MatListItemText>
                </UiLink>
              </UiItem>
            ))}
            <UiDivider />
          </Fragment>
        ))}
      </MatList>
      <BsModal
        open={isOpen}
        title="機構選擇"
        onCancel={modalToggle}
        oKDisplay={false}
        cancelDisplay={false}
        isFull={true}
      >
        <IconInput
          placeholder='搜尋機構名稱'
          onClick={value => onSubmitHandler(value, 'officialNameKeyword')}
          isFull={true}
        />
        <UiContent>
          <UiTable>
            <UiTr>
              <UiTh>機構名稱</UiTh>
              <UiTh>縣市</UiTh>
              <UiTh>鄉鎮市區</UiTh>
            </UiTr>
            {
              organizations.map((item) => {
                return (
                  <UiTr key={item.organization.id} onClick={() => goOtherOrg(item.organization.id)}>
                    <UiTd>{item.organization.officialName}</UiTd>
                    <UiTd>{item.organization.cityName}</UiTd>
                    <UiTd>{item.organization.districtName}</UiTd>
                  </UiTr>
                );
              })
            }
          </UiTable>
        </UiContent>
        <Box py={2} display="flex" justifyContent="flex-end">
          <TablePagination
            count={total}
            page={nowPage}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPage}
            labelRowsPerPage='每頁顯示'
            onChangeRowsPerPage={handleChangeRowsPerPage}
            component="div"
          />
        </Box>
      </BsModal>
    </MatDrawer>
  );
};
