import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Link as MatLink
} from '@material-ui/core';
import { Icon } from 'components';
import { UiBreadcrumbs } from './Breadcrumbs.style';


/**
 * 麵包屑
 */

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: '20px !important',
    height: '20px !important',
  },
}));

export const Breadcrumbs = ({ list }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <UiBreadcrumbs>
      {
        list.map(item => {
          if (item.link) {
            return (
              <MatLink
                className={classes.link}
                key={item.link}
                onClick={() => history.push(item.link)}
              >
                {
                  item.icon && <Icon className={classes.icon} title={item.name} name={item.icon} />
                }
                {
                  item.name
                }
              </MatLink>
            );
          } else {
            return (
              <li>{item.name}</li>
            );
          }
        })
      }
    </UiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string
    })
  )
};


