import styled from 'styled-components';
import { Select as MatSelect } from '@material-ui/core';

export const UiSelect = styled.div`
	position: relative;
	display: block;
	width: ${({ width }) => width && width};

	& > div {
		width: ${({ width }) => width && '100%'};
	}
`;

export const UiSelectLoading = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	opacity: 0.5;
	z-index: 1;

	& > div {
		margin: 1rem auto;
	}
`;

export const UiMatSelect = styled(MatSelect)`
	& > fieldset {
		border: 1px solid rgba(0, 0, 0, 0.42);
	}
`;