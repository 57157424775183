import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Icon, PhoneForm } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import { FormHelperText as MatFormHelperText, Box as MatBox } from '@material-ui/core';
import {
  UiNewBatchList,
  UiTitle,
  UiBatchItem,
  UiIconBox,
  UiInput,
  UiCloseBox,
  UiHelperText,
} from './NewBatchList.style';


/**
 * 批次新增Component
 */
const INPUT_KEY = {
  USERID: 'userId',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber'
};

// 對應ＡＰＩ的錯誤碼
const ERROR_KEY = {
  10001: '帳號不存在',
  30004: '成員已存在',
  30017: '成員邀請中',
};

export const NewBatchList = ({
  type = INPUT_KEY.USERID,
  onChange = () => { },
  errorData = null,
}) => {
  const [getUuid] = useUuid();
  const initState = [
    {
      id: getUuid(),
      value: ''
    }
  ];
  const [{
    defaultBatch,
    title,
  }, setState] = useSetState({
    defaultBatch: initState,
    title: '',
  });

  const onBatchItemChange = (value, id) => {
    const nextBatch = defaultBatch.map(item => {
      if (item.id === id) {
        item.value = value;
      }
      return item;
    });
    setState({
      defaultBatch: nextBatch
    });
  };

  const addNewBatch = () => {
    const newBatch = {
      id: getUuid(),
      value: ''
    };
    setState({
      defaultBatch: [...defaultBatch, newBatch]
    });
  };

  const removeBatchItem = id => {
    const nextBatch = defaultBatch.filter(item => item.id !== id);
    setState({
      defaultBatch: nextBatch
    });
  };

  useEffect(() => {
    const nextBatchList = defaultBatch.filter(item => {
      // type 為 mobileNumber 情況
      if (type === INPUT_KEY.MOBILE_NUMBER && item.value.length > 6) {
        return item;
      }
      // type 為 email & userId
      if (type !== INPUT_KEY.MOBILE_NUMBER && item.value !== '') {
        return item;
      }
    }).map(item => {
      return { [type]: item.value };
    });

    onChange(nextBatchList);
  }, [defaultBatch]);

  useEffect(() => {
    let nextBatch = [];
    errorData && Object.entries(errorData).map(([key, errorCode]) => {
      errorCode = errorCode.map(item => {
        return {
          id: getUuid(),
          value: item.userId || item.email || item.mobileNumber,
          errorMsg: key,
        };
      });
      nextBatch = nextBatch.concat(errorCode); // 將不同的錯誤碼合併成一個陣列
    });

    setState({ defaultBatch: nextBatch });
  }, [errorData]);

  useEffect(() => {
    switch (type) {
      case INPUT_KEY.USERID:
        setState({
          title: '請輸入成員Id',
          defaultBatch: initState
        });
        return;
      case INPUT_KEY.MOBILE_NUMBER:
        setState({
          title: '請輸入成員手機號碼',
          defaultBatch: initState
        });
        return;
      case INPUT_KEY.EMAIL:
        setState({
          title: '請輸入成員email',
          defaultBatch: initState
        });
        return;
      default:
        return '';
    }
  }, [type]);


  return (
    <UiNewBatchList>
      <UiTitle>{title}(類型不相同將不會進行檢查)</UiTitle>
      {
        defaultBatch.map(item => {
          return (
            <UiBatchItem key={item.id}>
              {
                type === INPUT_KEY.MOBILE_NUMBER ?
                  <PhoneForm
                    error={item.errorMsg ? true : false}
                    value={item.value || ''}
                    onChange={(countryCode, number) => onBatchItemChange((countryCode + number), item.id)}
                  /> :
                  <UiInput
                    type="text"
                    autoFocus
                    onChange={e => onBatchItemChange(e.target.value, item.id)}
                    placeholder={`輸入成員${type}`}
                    error={item.errorMsg || false}
                    value={item.value}
                  />
              }
              {
                item.errorMsg && <UiHelperText>
                  <MatFormHelperText>{ERROR_KEY[item.errorMsg]}</MatFormHelperText>
                </UiHelperText>
              }
              <UiCloseBox>
                <Icon title='移除項目' name='close' onClick={() => removeBatchItem(item.id)} />
              </UiCloseBox>
            </UiBatchItem>
          );
        })
      }
      <UiBatchItem onClick={addNewBatch}>
        <UiIconBox>
          <Icon title='新增項目' name='add' />
          <MatBox pl={1}>
            新增成員
          </MatBox>
        </UiIconBox>
      </UiBatchItem>
    </UiNewBatchList>
  );
};

NewBatchList.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  errorData: PropTypes.array,
};


