import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { UiIconTextAreaMain, UiIconTextArea, UiIconTextAreaInput, UiIconFileBox, UiFiles } from './IconTextArea.style';


/**
 * textArea with icon
 */

export const IconTextArea = ({
  defaultContent = '',
  defaultFiles = [],
  onKeyPress = () => { },
  onEsc=()=>{},
  isCanUploadFile = true,
  autoFocus=false,
}) => {
  const [{
    content,
    files,
    isCanSend,
    existFiles,
  }, setState] = useSetState({
    content: defaultContent,
    files: [],
    existFiles: defaultFiles,
    isCanSend: true
  });
  const onChangeHandler = value => {
    setState({
      content: value
    });
  };

  const onKeyPressHandler = event => {
    const keyCode = event.which || event.keyCode;
    if (keyCode === 13 && !event.shiftKey && isCanSend) {
      event.preventDefault();
      const params = {
        content,
        files,
        existFiles
      };
      if (content === '') return;
      onKeyPress(params);

      setTimeout(() => {
        setState({
          isCanSend: true
        });
      }, 2000);
      setState({
        files: [],
        content: '',
        isCanSend: false
      });
    }
  };

  const onKeyDownHandler = event => {
    const keyCode = event.which || event.keyCode;
    if(keyCode === 27){
      event.preventDefault();
      onEsc();
    }
  };

  const onFileChange = file => {
    if (defaultFiles.length > 0) return;
    setState({
      files: [file],
    });
  };

  const removeFile = () => {
    setState({
      files: [],
    });
  };

  const removeExistFile = () => {
    setState({
      existFiles: []
    });
  };

  return (
    <UiIconTextAreaMain>
      <UiIconTextArea>
        <UiIconTextAreaInput
          type="text"
          onChange={e => onChangeHandler(e.target.value)}
          onKeyPress={e => onKeyPressHandler(e)}
          onKeyDown={e=>onKeyDownHandler(e)}
          placeholder="留言..."
          value={content}
          maxLength="250"
          autoFocus={autoFocus}
        />
        {isCanUploadFile &&
          <UiIconFileBox>
            <Icon name="attachFile" haveBg={false} title="上傳檔案"/>
            <input type="file" onChange={e => onFileChange(e.target.files[0])}/>
          </UiIconFileBox>
        }
      </UiIconTextArea>
      {
        files.map((file, index) => {
          return (
            <UiFiles key={index}>{file.name}<Icon title='取消上傳' name='close' onClick={() => removeFile()} /></UiFiles>
          );
        })
      }
      {
        existFiles.map((file, index) => {
          return (
            <UiFiles key={index}>
              {file.attachmentName}
              <Icon title='取消上傳' name='close' onClick={() => removeExistFile()} />
            </UiFiles>
          );
        })
      }
    </UiIconTextAreaMain>
  );
};

IconTextArea.propTypes = {
  defaultContent: PropTypes.string,
  defaultFiles: PropTypes.array,
  onKeyPress: PropTypes.func,
  onEsc:PropTypes.func,
  isCanUploadFile: PropTypes.bool,
  autoFocus:PropTypes.bool
};


