import styled from 'styled-components';

export const UiSideBar = styled.div`
	display: block;
	width: 200px;
	height: 100%;
	border: 1px solid #d8edf0;

	& > div + div {
		border-top: 1px solid #d8edf0;
	}
`;

export const UiSiderBarItem = styled.div`
	& > a {
		display: flex;
		padding: 1rem 0.75rem;
		max-height: 52.5px;
		font-size: 1rem;
		text-decoration: none;
		color: ${({ theme }) => theme.palette.primary.sec};
		background-color: ${({ theme, active }) => active ? '#f4f3f0' : '#fff'};
		transition: 0.5s;
		cursor: pointer;

		&:hover {
			background-color: #f4f3f0;
		}

		& > svg {
			margin-right: 1rem;
			font-size: 1.5rem;
		}
	}
`;

