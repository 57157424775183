import styled from 'styled-components';

export const UiCardHead = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0.75rem;
	width: 100%;
`;

export const UiTeacherInfo = styled.div`

`;

export const UiUserName = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	font-weight: bold;

	& > span {
		padding-right: 5px;
	}
`;

export const UiCreateDate = styled.div`

`;

export const UiContentTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0.75rem;
	font-size: 1.8rem;
`;


export const UiContentTitleLeft = styled.div`
	flex: 8.5;
`;
export const UiContentTitleRight = styled.div`
	flex: 1.5;
`;
export const UiHomeWorkSign = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;

	& > div {
		width: 75%;
	}
`;

export const UiContent = styled.div`
	padding: 0.5rem 0.75rem;
`;

export const UiCardFooter = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 0.5rem 0.75rem;
`;


export const LabelGroup = styled.div`
	display: flex;
	margin-right: 1rem;
	min-width: ${({ userType }) => userType === 'teacher' ? '35%' : 'unset'};
`;

export const LabelInnerGroup = styled.div`
	display: flex;
	align-items: center;
	margin-right: 1rem;
`;

export const Label = styled.div`
	margin-right: 0.5rem;
	font-weight: bold;
`;

export const LabelValue = styled.div`
	position: relative;
	margin: 0 0.5rem;
`;

export const LabelModal = styled.div`
	position: absolute;
	top: -4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	background-color: #fff;
	border: 1px solid #00828f;
	border-radius: 5px;
	z-index: 1;
	box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.3);
`;

export const LabelModalContent = styled.div`
	position: relative;
	padding: 1rem 1.5rem;
	width: 100%;
	text-align: center;
`;
export const LabelClose = styled.div`
	position: absolute;
	top: 0.2rem;
	right: 0.2rem;
	display: flex;
	color: #fff;
	background-color: #000;
	border-radius: 5px;
	cursor: pointer;

	& > svg {
		padding: 0;
		font-size: 1rem;
	}
`;

export const UiChangeButtonBox = styled.div`
	padding: 0.5rem 0.75rem;
`;
export const UiStudentHomeworkForm = styled.div`
	form {
		padding: 0.5rem 0.75rem;

		& > div > div > button {
			margin: 8px 8px 8px 0;
		}
	}
`;

export const StudentModalContent = styled.div`
	font-size: 1.25rem;
	color: #000;
`;
export const StudentModalTitle = styled.div`

`;
export const StudentModalHomeworkTitle = styled.div`
	font-size: 2rem;
	color: #004b62;
`;
export const StudentModalHomeworkContent = styled.div`

`;

