/* eslint-disable max-len */
import React, { useCallback,useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Icon } from 'components';
import { useClass } from 'store/class';
// import { useUser } from 'store/user';
// import { ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { UiSideBar, UiSiderBarItem } from './SideBar.style';


/**
 * 左側欄
 */



export const SideBar = ({ type = 'home',sideBarData=[] }) => {
  const { organizationId, classId } = useParams();
  const [{ myClasses: { dataInfo: { isOwner, isHidden,navigationFunctions } } }] = useClass();
  // const [{ myOrganization }] = useUser();
  // const isShowInvite =
  // myOrganization?.organization?.groupOwnerInviteCustomerSetting === ORGANIZATION_SETTINGS_VALUE.ALLOW;
  const disableLink = e => {
    e.preventDefault();
  };

  const getBasePath = useCallback(() =>
    organizationId ? `/home/${organizationId}/class` : '/home/class'
    , [organizationId]);

  let data = type === 'home' ?
    [
      {
        isPublic: true,
        link: '/home',
        label: '返回列表',
        icon: 'exitToApp',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/info`,
        label: '簡介',
        icon: 'copyright',
        code:'brief',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/announcement`,
        label: '公告',
        icon: 'announcement',
        code:'announcement',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/sessions`,
        label: '課程',
        icon: 'importContacts',
        code:'session',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/paste`,
        label: '貼文',
        icon: 'message',
        code:'post',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/album`,
        label: '相簿',
        icon: 'photoAlbum',
        code:'album',
      },
      {
        isPublic: false,
        link: `${getBasePath()}/${classId}/summary`,
        label: '學習歷程',
        icon: 'history',
      },
      {
        isPublic: true,
        link: '',
        label: '學習輔助工具',
        icon: 'category',
        onClick: e => {
          disableLink(e);
        }
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/homeworks`,
        label: '作業',
        icon: 'homeWork',
        code:'mission',
      },
      {
        isPublic: true,
        link: isHidden ? '' : `${getBasePath()}/${classId}/secretdiscuss`,
        label: '導師商談室',
        icon: 'textsms',
        onClick: e => {
          isHidden &&
            disableLink(e);
        },
        code:'counseling',
      },
      {
        isPublic: true,
        link: `${getBasePath()}/${classId}/member`,
        label: '成員',
        icon: 'people',
        code:'member',
      },
      {
        isPublic: true,
        link: isHidden ? '' : `${getBasePath()}/${classId}/invite`,
        label: '邀請',
        icon: 'drafts',
        onClick: e => {
          isHidden && disableLink(e);
        },
        code:'invitation',
      },
      {
        isPublic: true,
        link: isOwner ? `${getBasePath()}/${classId}/setting` : '',
        label: '設定',
        icon: 'settings',
        onClick: e => {
          !isOwner &&
            disableLink(e);
        },
        code:'setting',
      }
    ] :
    [
      {
        isPublic: true,
        link: '/home/memberCenter/profile',
        label: '基本資料',
        icon: 'person',
      },
      {
        isPublic: true,
        link: '/home/memberCenter/changePassword',
        label: '修改密碼',
        icon: 'vpnKey',
      },
      // {
      //   isPublic: true,
      //   link: '/home/memberCenter/deviceDetect',
      //   label: '設備檢測',
      //   icon: 'permDeviceInformation',
      // },
    ];

    if(sideBarData.length > 0) data = sideBarData;

    const nextData = useMemo(()=>{
      if(!navigationFunctions) return data;
      const settingSet = new Set(navigationFunctions);
      const newData = data.filter(item=>!item.code || settingSet.has(item.code));
      return newData;
    },[navigationFunctions]);

  return (
    <UiSideBar>
      {
        data && nextData.filter(item => {
          return isOwner ? item : item.isPublic === true;
        }).map((item, index) => {
          return (
            <UiSiderBarItem key={index}>

              {
                <NavLink
                  activeStyle={
                    item.link ? { backgroundColor: '#f4f3f0' } : { color: '#ccc', cursor: 'default' }
                  }
                  to={item.link}
                  onClick={item.onClick}
                >
                  <Icon name={item.icon} />
                  {item.label}
                </NavLink>
              }
            </UiSiderBarItem>
          );
        })
      }
    </UiSideBar>
  );
};

SideBar.propTypes = {
  type: PropTypes.string,
  sideBarData:PropTypes.array
};
