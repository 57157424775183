import React from 'react';
import PropTypes from 'prop-types';
import { UiMemberCenterCard, UiCardContent, UiCardTitle } from './MemberCenterCard.style';


/**
 * 會員中心的卡牌組件
 */

export const MemberCenterCard = ({
  title = '預設標題',
  children,
}) => {
  return (
    <UiMemberCenterCard>
      <UiCardTitle>{title}</UiCardTitle>
      <UiCardContent>
        {children}
      </UiCardContent>
    </UiMemberCenterCard>
  );
};

MemberCenterCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};


