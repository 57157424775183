import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider as MatStylesProvider, ThemeProvider as MatThemeProvider } from '@material-ui/core/styles';
import zhTwLocale from 'date-fns/locale/zh-TW';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import configureStore from 'store';
import * as serviceWorker from './serviceWorker';
import theme from 'theme';
import './i18n';
import { Alert, Beforeunload } from 'components';
import App from 'components/App';
import { BodyStyle } from './index.style';

const store = configureStore({});

const Main = () => (
  <Beforeunload onBeforeunload={() => '是否要離開囉？'}>
    <CssBaseline />
    <MatStylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MatThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTwLocale}>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2000}
              anchorOrigin={
                {
                  vertical: 'top',
                  horizontal: 'right'
                }
              }
            >
              <Alert />
              <Provider store={store}>
                <BrowserRouter>
                  <BodyStyle />
                  <App />
                </BrowserRouter>
              </Provider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </MatThemeProvider>
      </ThemeProvider>
    </MatStylesProvider>
  </Beforeunload >
);

const root = document.getElementById('root');

ReactDOM.render(Main(), root);

if (module.hot) {
  module.hot.accept('components/App', () => {
    require('components/App');
    ReactDOM.render(Main(), root);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
