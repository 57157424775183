import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { EDIT_STATUS } from 'constants/index';


import { Table, IconInput, Select, Icon, Button } from 'components';
import { UiAvatar } from './TeacherManagementTable.style';
import { UiTable, UiActionBox, UiflexBox } from 'styles';
import { useSetState } from 'utils/hooks/useSetState';
import { useTeacher } from 'store/teacher';
import { useUser } from 'store/user';


/**
 * 教師管理列表表單
 */

const schema = {
  nickname: {
    name: '機構暱稱',
    defaultValue: '',
  },
  originalName:{
    name:'姓名',
    defaultValue: '',
  },
  originalNickname: {
    name: '暱稱',
    defaultValue: '',
  },
  avatar: {
    name: '照片',
    defaultValue: ''
  },
  mobileNumber: {
    name: '聯絡電話',
    defaultValue: '',
  },
  role: {
    name: '權限',
    defaultValue: ''
  },
};

const stateShowText = [
  {
    id: 'role',
    state: {
      staff: '教師',
      owner: '機構管理者',
    }
  }
];

const stateOptions = [
  {
    name: '手機',
    value: 'mobileNumber'
  },
  {
    name: '暱稱',
    value: 'nickname'
  }
];

export const TeacherManagementTable = () => {
  const { organizationId } = useParams();
  const history = useHistory();

  // store teacher
  const [{ teachers: { data, total } }, { getTeachers }] = useTeacher();
  const [{ nowPage, rowsPage, searchKey, searchValue, tableData }, setState] = useSetState({
    nowPage: 0,
    rowsPage: 10,
    searchKey: '',
    searchValue: '',
    tableData: [],
  });

  // store user
  const [
    { myOrganization, profile }
  ] = useUser();
  const { organization } = myOrganization;
  const ownerId = organization.ownerId;
  const isOwnerId = profile.id === ownerId;

  // table 操作
  const ActionComponents = params => {
    const teacherId = params.params.id;
    // 判斷此行是不是『機構管理者』
    const teacherIsOwner = organization.ownerId === teacherId;

    return (
      <>
        {
          teacherIsOwner ? (isOwnerId ? <Icon
            onClick={() =>
              history.push(
                `/organization/${organizationId}/teachers/${teacherId}/${EDIT_STATUS.EDIT}`
              )}
            name='edit'
          /> : '')
          : <Icon
            onClick={() =>
              history.push(
                `/organization/${organizationId}/teachers/${teacherId}/${EDIT_STATUS.EDIT}`
              )}
            name='edit'
          />
        }
      </>
    );
  };

  const changePage_Rows = params => {
    const { newPage, newRowsPage } = params;
    setState({
      nowPage: newPage,
      rowsPage: newRowsPage
    });
  };

  const submitSelectHandler = value => {
    setState({
      searchKey: value,
      nowPage: 0
    });
  };

  const submitInputHandler = value => {
    if (!searchKey) {
      alert('請先選擇搜尋目標');
      return;
    }
    setState({
      searchValue: value,
      nowPage: 0
    });
  };

  useEffect(() => {
    const params = {
      nowPage,
      rowsPage,
      [searchKey]: searchValue
    };
    getTeachers(params);
  }, [nowPage, rowsPage, searchValue]);

  useEffect(() => {
    const nextData = data.map(item => {
      if(item.id === ownerId) {
        item.role = 'owner';
      }
      return {
        ...item,
        avatar: <UiAvatar alt={item.nickname} src={item.thumbnailUrl} />,
      };
    });
    
    setState({
      tableData: nextData,
    });
  }, [data]);

  return (
    <UiTable>
      <UiActionBox>
        <UiflexBox>
          <Select label="搜尋目標" options={stateOptions} submitHandler={submitSelectHandler} />
          <IconInput placeholder="搜尋" onChange={submitInputHandler} />
        </UiflexBox>
        <Button
          buttonColor='highlight'
          icon='add'
          onClick={
            () => { history.push(`/organization/${organizationId}/teachers/${EDIT_STATUS.CREATE}`); }
          }>新增教師</Button>
      </UiActionBox>
      <Table
        data={tableData}
        schema={schema}
        stateShowText={stateShowText}
        changePage_Rows={changePage_Rows}
        totalPage={total}
        ActionComponents={ActionComponents}
        nowPage={nowPage}
      />
    </UiTable>
  );
};



