import React from 'react';
import PropTypes from 'prop-types';
import {
  UiHomeMemberCenterLayout,
  UiMemberCenterTitle,
  UiHomeMemberCenterContent,
  UiChildrenContainer,
} from './HomeMemberCenterLayout.style';
import { Header, Footer, SideBar } from 'components';

/**
 * 會員中心layout
 */

export const HomeMemberCenterLayout = ({ children }) => {
  return (
    <UiHomeMemberCenterLayout>
      <Header />
      <UiMemberCenterTitle>
        會員中心
      </UiMemberCenterTitle>
      <UiHomeMemberCenterContent>
        <SideBar type='memberCenter' />
        <UiChildrenContainer>{children}</UiChildrenContainer>
      </UiHomeMemberCenterContent>
      <Footer />
    </UiHomeMemberCenterLayout>
  );
};

HomeMemberCenterLayout.propTypes = {
  children: PropTypes.node,
};


