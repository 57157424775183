import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';
import { useUser } from 'store/user';
import MatImage from 'material-ui-image';
import homeworkLate from 'assets/images/late.svg';
import homeworkDone from 'assets/images/done.svg';
import {
  Button, Icon, Card,
  ClickablePopoverMenu, Modal, Form,
  UploadButton,
  HomeworkCardEdit
} from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { EDIT_STATUS } from 'constants/index';
import { formatTimestamp } from 'utils/date';

import {
  UiCardHead,
  UiContentTitle,
  UiContent,
  UiCardFooter,
  UiTeacherInfo,
  UiUserName,
  UiCreateDate,
  LabelGroup,
  Label,
  LabelValue,
  UiChangeButtonBox,
  UiStudentHomeworkForm,
  LabelInnerGroup,
  LabelModal,
  LabelClose,
  LabelModalContent,
  StudentModalContent,
  StudentModalTitle,
  StudentModalHomeworkTitle,
  StudentModalHomeworkContent,
  UiContentTitleLeft,
  UiContentTitleRight,
  UiHomeWorkSign,
} from './HomeworkCardRead.style';


/**
 * 作業卡牌組件
 */

const defaultData = {
  avatar: '',
  ownerName: '測試人員',
  publishedAt: '2019/08/08',
  title: '這是作業標題',
  description: '這是作業內容',
  dueAt: '2019/08/08',
  attachmentUrl: '',
  number: 10,
  total: 30
};

export const HomeworkCardRead = ({
  data = defaultData,
  userType = 'student',
  isAdmin = false,
  isLoading,
  onDelete = () => { },
  onPin = () => { },
  onCardReadFormSubmit = () => { },
  onUploaded = () => { },
}) => {
  const [{ profile: { id: userId } }] = useUser();
  const history = useHistory();
  const { organizationId, classId } = useParams();
  const {
    id,
    ownerName,
    formatPublishedAt,
    formatDueAt,
    title,
    description,
    attachmentUrl,
    number,
    total,
    isExpired,
    canOperating,
    canEdit,
    isPinned,
    submissions,
    dueAt
  } = data;

  const [{
    isModalOpen,
    cardStatus,
    showHomeworkForm,
    fileModal,
    commentModal,
    submitStudentHomeworkData,
  }, setState] = useSetState({
    isModalOpen: false,
    cardStatus: null,
    showHomeworkForm: false,
    fileModal: false,
    commentModal: false,
    submitStudentHomeworkData: null
  });




  const deleteModalConfig = {
    text: '是否要將此項作業刪除？',
    confirmAction: () => onDelete(data)
  };

  const pinModalConfig = {
    text: '是否要將此項作業置頂？',
    confirmAction: () => onPin(data)
  };
  const submitModalText = () => {
    if (!submissions || !submissions[userId]) return;
    const { submittedAt } = submissions[userId];
    return (<StudentModalContent>
      <StudentModalTitle>作業</StudentModalTitle>
      <StudentModalHomeworkTitle>{title}</StudentModalHomeworkTitle>
      <StudentModalHomeworkContent>
        <div>已於{formatTimestamp(submittedAt, 'yyyy-MM-dd HH:mm:ss')}繳交</div>
        <div>重複繳交僅會保留最後一次的繳交資料</div>
        <div>確認是否仍要重複繳交作業？</div>
      </StudentModalHomeworkContent>
    </StudentModalContent>);
  };
  const submitConfig = {
    content: submitModalText(data),
    confirmAction: () => onHomeworkFormSubmitHandler(submitStudentHomeworkData, 'modal')
  };

  const setModalConfig = () => {
    switch (cardStatus) {
      case EDIT_STATUS.DELETE:
        return deleteModalConfig;
      case EDIT_STATUS.SUBMIT:
        return submitConfig;
      default:
        return pinModalConfig;
    }
  };


  const ModalConfig = setModalConfig();

  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const setCardStatus = status => () => setState({ cardStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });

  let popoverMenuList = [
    { label: '刪除', func: setCardStatus(EDIT_STATUS.DELETE), iconName: 'delete' },
  ];
  if (isAdmin) {
    popoverMenuList = [
      {
        label: '置頂',
        func: setCardStatus(EDIT_STATUS.ALIGN_TOP),
        iconName: 'verticalAlignTop'
      }, ...popoverMenuList];
  }

  if (canEdit) {
    popoverMenuList = [
      {
        label: '編輯',
        func: setCardStatus(EDIT_STATUS.EDIT),
        iconName: 'edit'
      }
      , ...popoverMenuList];
  }

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };
  const doShowHomeworkForm = isExpired => {
    if (isExpired) return;
    setState({
      showHomeworkForm: !showHomeworkForm
    });
  };

  const onHomeworkFormSubmitHandler = (value, type = '') => {
    if (submissions) {
      if (submissions[userId] && type !== 'modal') {
        setState({
          submitStudentHomeworkData: value,
          cardStatus: EDIT_STATUS.SUBMIT,
          isModalOpen: true,
        });
        return;
      }
    }

    const dataValue = { data, ...value };
    onCardReadFormSubmit(dataValue);
  };

  const goSubmitted = () => {
    history.push(organizationId ?
      `/home/${organizationId}/class/${classId}/homeworks/${id}/submitted` :
      `/home/class/${classId}/homeworks/${id}/submitted`);
  };

  const CardHeader = (
    <UiCardHead>
      <UiTeacherInfo>
        <UiUserName>
          <span>{ownerName}</span>
          {isPinned && <Icon name='star' icolor='#cf2b30' haveBg={false} />}
        </UiUserName>
        <UiCreateDate>{formatPublishedAt}</UiCreateDate>
      </UiTeacherInfo>
      {(userType === 'teacher') ?
        <Button
          onClick={goSubmitted}
          buttonColor={!canOperating ? 'disable' : 'normal'}
          disabled={!canOperating}
        >繳交狀況</Button> :
        <Button
          onClick={() => { doShowHomeworkForm(isExpired); }}
          buttonColor={isExpired ? 'disable' : 'normal'}
          disabled={isExpired}
        >
          繳交作業
        </Button>
      }
    </UiCardHead>
  );



  const formButtons = [
    {
      text: '送出作業',
      type: 'submit'
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { doShowHomeworkForm(); }
    }
  ];

  const schema = {
    comment: {
      component: MatTextField,
      elementProps: {
        label: '作業內容',
        value: '',
        helperText: '',
        variant: 'outlined',
        multiline: true,
        rows: 3
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    file: {
      component: UploadButton,
      elementProps: {
        label: '附加檔案',
        value: '',
        helperText: '',
        filesLimit: userType === 'student' ? 10 : 1
      }
    }
  };

  const CardFooter = (
    <>
      <UiCardFooter>
        <LabelGroup>
          <LabelInnerGroup>
            <Label>截止日期</Label>
            <LabelValue>{formatDueAt}</LabelValue>
          </LabelInnerGroup>
          {
            (submissions && submissions[userId]) &&
            <LabelInnerGroup>
              <LabelValue>{formatTimestamp(submissions[userId].submittedAt, 'yyyy-MM-dd HH:mm:ss')}</LabelValue>
              <LabelValue>第{submissions[userId].submitCount}次繳交</LabelValue>
              <LabelValue>
                {
                  fileModal &&
                  <LabelModal>
                    <LabelModalContent>
                      <LabelClose
                        onClick={() => { setState({ fileModal: false }); }}>
                        <Icon name='close' size='small' haveBg={false} />
                      </LabelClose>
                      {submissions[userId].attachmentName}
                    </LabelModalContent>
                  </LabelModal>
                }

                <Button
                  onClick={() => { setState({ fileModal: !fileModal }); }}
                  buttonColor={submissions[userId].attachmentUrl ? 'normal' : 'disable'}
                  disabled={!submissions[userId].attachmentUrl}>附檔</Button>
              </LabelValue>
              <LabelValue>
                {
                  commentModal &&
                  <LabelModal>
                    <LabelModalContent>
                      <LabelClose
                        onClick={() => { setState({ commentModal: false }); }}>
                        <Icon name='close' size='small' haveBg={false} />
                      </LabelClose>
                      {submissions[userId].comment}
                    </LabelModalContent>
                  </LabelModal>
                }
                <Button
                  onClick={() => { setState({ commentModal: !commentModal }); }}
                  buttonColor={submissions[userId].comment ? 'normal' : 'disable'}
                  disabled={!submissions[userId].comment}
                >留言
                </Button>
              </LabelValue>
            </LabelInnerGroup>
          }
        </LabelGroup>
        <LabelGroup>
          <Label>繳交人數</Label>
          <LabelValue>{`${number}/${total}`}</LabelValue>
        </LabelGroup>
        {canOperating &&
          <ClickablePopoverMenu menuList={popoverMenuList}>
            <Icon haveBg={false} name="moreHoriz" icolor="#000"></Icon>
          </ClickablePopoverMenu>
        }
      </UiCardFooter>
      {showHomeworkForm &&
        <UiStudentHomeworkForm>
          <Form schema={schema} buttons={formButtons} onSubmit={onHomeworkFormSubmitHandler} isLoading={isLoading} />
        </UiStudentHomeworkForm>
      }

    </>
  );
  const onSubmitHandler = val => {
    const { id } = data;
    const nextData = { id, ...val, isLateSubmissionAllowed: val.isLateSubmissionAllowed || false };
    onUploaded(nextData, nextData.file);
  };

  const changeCardStateHandler = () => {
    setState({
      cardStatus: EDIT_STATUS.READ
    });
  };

  return (
    <>
      {
        cardStatus === EDIT_STATUS.EDIT ?
          <HomeworkCardEdit
            type={cardStatus}
            data={data}
            isOpen={true}
            onSubmit={onSubmitHandler}
            changeCardState={() => changeCardStateHandler()}
          /> :
          <>
            <Card header={CardHeader} footer={CardFooter}>
              <UiContentTitle>
                <UiContentTitleLeft>
                  {title}
                </UiContentTitleLeft>
                {
                  (submissions && submissions[userId]) &&
                  <UiContentTitleRight>
                    <UiHomeWorkSign isLate={submissions[userId].submittedAt > dueAt}>
                      <MatImage src={submissions[userId].submittedAt > dueAt ? homeworkLate : homeworkDone} alt="org" />
                    </UiHomeWorkSign>
                  </UiContentTitleRight>

                }
              </UiContentTitle>
              <UiContent dangerouslySetInnerHTML={{ __html: description }}></UiContent>
              {attachmentUrl &&
                <UiChangeButtonBox>
                  <Button onClick={() => { window.open(`${attachmentUrl}`); }}>下載附件</Button>
                </UiChangeButtonBox>
              }

            </Card>
            <Modal
              isOpen={isModalOpen}
              text={ModalConfig.text}
              content={ModalConfig.content || ''}
              buttons={buttons}
              getModalState={getModalStateHandler}
            />
          </>
      }

    </>
  );
};

HomeworkCardRead.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    userName: PropTypes.string,
    createData: PropTypes.string,
    contentTitle: PropTypes.string,
    content: PropTypes.string,
    deadline: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number
  }),
  userType: PropTypes.string,
  onDelete: PropTypes.func,
  onPin: PropTypes.func,
  onCardReadFormSubmit: PropTypes.func,
  onUploaded: PropTypes.func,
  isLoading: PropTypes.bool,
  isAdmin: PropTypes.bool
};


