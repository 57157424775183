import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTime, format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

import { DatePicker, Icon } from 'components';
import { useSetState } from 'utils/hooks/useSetState';
import { useUuid } from 'utils/hooks/useUuid';
import {
  UiTodoList,
  UiTitle,
  UiTodoItem,
  UiClock,
} from './TodoList.style';


/**
 * todoList
 */

const useStyles = makeStyles({
  root: {
    cursor: 'pointer'
  },
});


export const TodoList = ({ data = [], limit = 10, onChange = () => { } }) => {
  const classes = useStyles();
  const [getUuid] = useUuid();
  const [{
    todoList,
    pickerState,
    todoId
  }, setState] = useSetState({
    todoList: data,
    pickerState: false,
    todoId: ''
  });

  //新增項目
  const addNewTodo = () => {
    if (todoList.length >= limit) return;
    const newTodo = {
      id: getUuid(),
      description: '',
      dueAt: null
    };
    setState({
      todoList: [...todoList, newTodo]
    });
  };

  //移除項目
  const removeTodo = id => {
    const nextTodoList = todoList.filter(item => item.id !== id);
    setState({
      todoList: nextTodoList
    });
  };

  //日期onChange
  const onDateChange = date => {
    const timeFormat = new Date(new Date(date).toLocaleDateString());
    const dataTimestamp = getTime(timeFormat);
    const nextTodoList = todoList.map(li => {
      if (li.id === todoId) li.dueAt = dataTimestamp;
      return li;
    });
    setState({
      todoList: nextTodoList
    });
  };

  //Input onChange
  const onChangeInput = (value, id) => {
    const nextTodoList = todoList.map(li => {
      if (li.id === id) li.description = value;
      return li;
    });
    setState({
      todoList: nextTodoList
    });
  };

  //開關選擇日期彈窗
  const changePickerState = id => {
    setState({
      pickerState: !pickerState,
      todoId: id
    });
  };

  useEffect(() => {
    const nextTodoList = todoList.filter(item => item.description !== '');
    onChange(nextTodoList);
  }, [todoList]);


  useEffect(() => {
    setState({
      todoList: data
    });
  }, [data]);

  return (
    <UiTodoList>
      <UiTitle>
        <Icon title='提醒事項' name='checkBox' />
        提醒事項
      </UiTitle>
      {
        todoList.map(todoItem => {
          return (
            <UiTodoItem key={todoItem.id}>
              <Icon name='checkBoxOutlineBlank' />
              <input
                type="text"
                value={todoItem.description}
                maxLength='8'
                onChange={e => onChangeInput(e.target.value, todoItem.id)}
                autoFocus
              />
              <div>
                {
                  (!todoItem.dueAt || todoItem.dueAt === null) ?
                    '' : format(new Date(todoItem.dueAt), 'yyyy-MM-dd')
                }
              </div>
              <UiClock>
                <Icon
                  className={classes.root}
                  title='新增時間'
                  name='accessAlarm'
                  onClick={() => changePickerState(todoItem.id)}
                />
              </UiClock>
              <Icon className={classes.root} title="移除項目" name='close' onClick={() => removeTodo(todoItem.id)} />
            </UiTodoItem>
          );
        })
      }
      {
        todoList.length >= limit ||
        <UiTodoItem isClick={true} onClick={() => addNewTodo()}>
          <Icon title='新增項目' name='add' />
          新增項目
        </UiTodoItem>
      }
      <DatePicker
        onChange={date => onDateChange(date)}
        open={pickerState}
        onClose={() => changePickerState()}
      />
    </UiTodoList>
  );
};

TodoList.propTypes = {
  data: PropTypes.array,
  limit: PropTypes.number,
  onChange: PropTypes.func
};


