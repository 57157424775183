import React from 'react';
import PropTypes from 'prop-types';

import { Header, Footer } from 'components';
import { UiSingleLayout, UiSingleContent } from './SingleLayout.style';


/**
 * 沒有側邊欄的版型
 */

export const SingleLayout = ({ children }) => {
  return (
    <UiSingleLayout>
      <Header />
      <UiSingleContent>
        {children}
      </UiSingleContent>
      <Footer />
    </UiSingleLayout>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
};


