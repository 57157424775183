import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MatAvatar } from '@material-ui/core';

import { Slider, Icon, IconTextArea } from 'components';
import { useCalTime } from 'utils/hooks/useCalTime';
import {
  UiAlbumSlider,
  UiAlbumContent,
  UiClose,
  UiAlbumTop,
  UiAlbumItem,
  UiAlbumButtom,
  UiAlbumPost,
  UiAlbumUser,
  UiAlbumUserInfo,
  UiAlbumPostContent,
  UiPostThumbUp,
  UiAlbumPostActionBox,
  UiAlbumPostActionItem,
  UiAlbumReplieBox,
  UiAlbumInput,
  UiAlbumReplieItem,
  UiAlbumReplieContent,
  UiAlbumReplieAction,
  UiShowAllMessage,
  UiThumbUp,
} from './AlbumSlider.style';

/**
 * slider 有內容
 */


const sliderSetting = {
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  adaptiveHeight: true,
};
export const AlbumSlider = ({
  type = 'contentSlider',
  isOpen,
  close = () => { },
  defaultData,
  replieMessage = () => { },
  showAllReplies = () => { },
  changeLikeUserState = () => { },
  initalSlide = 0,
  ownerId = ''
}) => {
  const [calTime] = useCalTime();
  const isLiked = () => defaultData.likedUsers ?
    defaultData.likedUsers.some(item => item === ownerId) :
    false;

  return isOpen && (
    <UiAlbumSlider onClick={e => close(e)}>
      <div>
        <UiAlbumContent>
          <UiAlbumTop>
            <UiClose onClick={() => close()}><Icon name="close" haveBg={false} /></UiClose>
            <Slider config={sliderSetting} initalSlide={initalSlide}>
              {
                defaultData.attachments.map(item => {
                  return (
                    <UiAlbumItem key={item.attachmentUrl}>
                      <div>
                        {
                          item.attachmentType.split('/')[0] === 'image' ?
                            <img src={item.attachmentUrl} alt={item.attachmentUrl} /> :
                            <video controls>
                              <source src={item.attachmentUrl} type={item.attachmentType} />
                            </video>
                        }
                      </div>
                    </UiAlbumItem>
                  );
                })
              }
            </Slider>
          </UiAlbumTop>
          {type === 'contentSlider' &&
            <UiAlbumButtom>
              <UiAlbumPost>
                <UiAlbumUser>
                  <MatAvatar src="" />
                  <UiAlbumUserInfo>
                    <div>{defaultData.ownerName}</div>
                    <div>{calTime(defaultData.publishedAt)}</div>
                  </UiAlbumUserInfo>
                </UiAlbumUser>
                <UiAlbumPostContent>
                  {defaultData.content}
                </UiAlbumPostContent>
                <UiPostThumbUp>
                  <Icon title='讚' name='thumbUp' />
                  <span>{defaultData.likedUsers ? defaultData.likedUsers.length : 0}</span>
                </UiPostThumbUp>
                <UiAlbumPostActionBox>
                  <UiAlbumPostActionItem
                    onClick={() => changeLikeUserState(defaultData.id, isLiked(), 'picture')}
                    isLiked={isLiked()}
                  >
                    <Icon title='讚' name='thumbUp' haveBg={false} />
                    <span>讚</span>
                  </UiAlbumPostActionItem>
                </UiAlbumPostActionBox>
              </UiAlbumPost>
              {
                (
                  defaultData.replies &&
                  defaultData.replies.total !== defaultData.replies.posts.length
                ) &&
                <UiShowAllMessage>
                  <span
                    onClick={() => showAllReplies(defaultData.id, defaultData.replies.total)}
                  >
                    顯示全部留言
              </span>
                </UiShowAllMessage>
              }
              {
                defaultData.replies &&
                <UiAlbumReplieBox>
                  {
                    defaultData.replies.posts.map(replie => {
                      replie.isLiked = replie.likedUsers ? replie.likedUsers.some(item => item === ownerId) : false;
                      return (
                        <UiAlbumReplieItem key={replie.id}>
                          <UiAlbumUser>
                            <MatAvatar src="" alt={replie.ownerId} />
                          </UiAlbumUser>
                          <UiAlbumReplieContent>
                            <div>
                              <span>{replie.ownerName}</span>
                              {
                                replie.content
                              }
                              {
                                (replie.attachments && replie.attachments.length > 0) &&
                                <img
                                  src={replie.attachments[0].attachmentUrl}
                                  alt={replie.attachments[0].attachmentName}
                                />
                              }
                              {
                                replie.likedUsers &&
                                <UiThumbUp>
                                  <Icon title='讚' name='thumbUp' />
                                  {replie.likedUsers.length}
                                </UiThumbUp>
                              }
                            </div>
                            <UiAlbumReplieAction>
                              <span onClick={() => changeLikeUserState(replie.id, replie.isLiked)}>
                                {replie.isLiked ? '收回讚' : '讚'}
                              </span>
                            </UiAlbumReplieAction>
                          </UiAlbumReplieContent>
                        </UiAlbumReplieItem>
                      );
                    })
                  }
                </UiAlbumReplieBox>
              }
              <UiAlbumInput>
                <IconTextArea isCanUploadFile={false} onKeyPress={params => replieMessage(params, defaultData.id)} />
              </UiAlbumInput>
            </UiAlbumButtom>}
        </UiAlbumContent>
      </div>
    </UiAlbumSlider>
  );
};

AlbumSlider.propTypes = {
  type: PropTypes.oneOf(['contentSlider', 'slider']),
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  defaultData: PropTypes.object,
  replieMessage: PropTypes.func,
  showAllReplies: PropTypes.func,
  changeLikeUserState: PropTypes.func,
  initalSlide: PropTypes.number,
  ownerId: PropTypes.string
};


