import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { Icon } from 'components';
import schoolDefaultImage from 'assets/images/banner.jpg';
import {
  UiSessionCard,
  UiSessionCardImage,
  UiSessionCardContent,
  UiSessionCardLocal,
  UiSessionCardTitile,
  UiSessionCardDate,
  UiSessionCardInfo,
  UiSessionCardBorder,
  UiSessionCardType,
  UiAvatar,
  UiIcon,
} from './SessionCard.style';


/**
 * 課程卡牌
 */

const sessionType = {
  over: {
    color: '#ccc',
    text: '已結束'
  },
  class: {
    color: '#f00',
    text: '上課中'
  },
  pendding: {
    color: '#ff9900',
    text: '準備中'
  },
  yet: {
    color: '#9fc5f8',
    text: '未開始'
  },
};

export const SessionCard = ({
  avatarPath = '',
  coverPath = '',
  groupName = '',
  sessionName = '',
  date,
  time,
  type = '',
  people,
  teacher = '',
  role = 'user',
  onClick = () => { },
}) => {

  return (
    <UiSessionCard onClick={onClick}>
      <UiSessionCardType color={sessionType[type].color}>{sessionType[type].text}</UiSessionCardType>
      <UiIcon>
        <Icon
          name={role === 'user' ? 'tagFaces' : 'recordVoiceOver'}
          title={role === 'user' ? '學生' : '老師'}
        />
      </UiIcon>
      <UiSessionCardImage>
        <img
          src={coverPath}
          onError={(e) => { e.target.onerror = null; e.target.src = schoolDefaultImage; }}
          alt={groupName}
        />
      </UiSessionCardImage>
      <UiSessionCardContent>
        <UiSessionCardLocal>{groupName}</UiSessionCardLocal>
        <UiSessionCardTitile>{sessionName}</UiSessionCardTitile>
        <UiSessionCardDate color={sessionType[type].color}>
          <span>{format(date, 'yyyy/MM/dd')}</span>
          <span>{format(date, 'HH:mm')}</span>
          <span>{time}分</span>
        </UiSessionCardDate>
        <UiSessionCardInfo>
          <span><UiAvatar src={avatarPath} alt="萬" />{teacher} 老師</span>
          <span><Icon name='people' haveBg={false} />{people}</span>
        </UiSessionCardInfo>
      </UiSessionCardContent>
      <UiSessionCardBorder color={sessionType[type].color} />
    </UiSessionCard>
  );
};

SessionCard.propTypes = {
  avatarPath: PropTypes.string,
  coverPath: PropTypes.string,
  groupName: PropTypes.string,
  sessionName: PropTypes.string,
  date: PropTypes.number,
  time: PropTypes.number,
  type: PropTypes.string,
  people: PropTypes.number,
  teacher: PropTypes.string,
  role: PropTypes.string,
  onClick: PropTypes.func,
};


