import styled from 'styled-components';

export const UiForm = styled.form`
	display: block;
	padding: 1.5rem;
`;

export const UiControllerWrapper = styled.div`
	margin: 1.5rem 0;
	${({ css }) => ({ ...css })}
`;

export const UiButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	& > button {
		margin: 0.5rem 0;
	}

	& > button + button {
		margin: 0.5rem 0.5rem 0.5rem;
	}
`;

export const UiFormTitle = styled.div`
	display: flex;
	justify-content: center;
	font-size: 2rem;
`;
