import React, { useEffect, Children, Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Button, BsModal, } from 'components';
import { UiForm, UiControllerWrapper, UiButtonWrapper, UiFormTitle } from './Form.style';
import { useHistory, useLocation, } from 'react-router-dom';
import { useSetState } from 'utils/hooks/useSetState';
import { isDeepEqual } from 'utils/deepEqual';

/**
 * Form
 */

export const Form = ({
  title = '',
  schema = {},
  buttons = [],
  isLoading = false,
  needHide = false,
  onSubmit = () => { },
  onFormChange = () => { },
  children,
  resetTrigger = false,
  blackList = [],
  prevFormValue = {},
}) => {
  const [{
    nextLocation,
    isModalOpen,
    isModalCancelClick,
  }, setState] = useSetState({
    nextLocation: {},
    isModalOpen: false,
    isModalCancelClick: false,
  });
  const history = useHistory();
  const { pathname } = useLocation();
  const { handleSubmit, errors, control, watch, reset, getValues } = useForm();
  const formHandler = callBack => {
    callBack && callBack();
  };
  const watchAllFields = watch();

  /* 彈出視窗 放棄變更 click */
  const onCancelHandler = () => {
    setState({ isModalCancelClick: true });
  };

  /* 彈出視窗 儲存變更 click */
  const onOkHandler = () => {
    setState({ isModalOpen: false });
    handleSubmit(onSubmit)();
  };

  /* 表單有無改變 */
  const isFormChange = (isBeforeunloadEvent, location) => {
    if(pathname === '/') return;
    
    let isChange = false;

    const prev = prevFormValue.current;
    const current = getValues();

    // 表單目前資料，undefined, '' 統一轉為 null
    Object.keys(current).forEach(key => { if(!current[key]) current[key] = null; });
    // 表單目前資料，權限為空陣列，轉為 null
    if(current['permissionGroups']?.length === 0) current['permissionGroups'] = null;

    console.log('prev:', prev);
    console.log('current:', current);

    // 表單上一個資料及目前資料比較
    if(isDeepEqual(prev, current)){
      // 表單無改變
      isChange = false;
    }else{
      // 表單有改變
      isChange = true;
      !isBeforeunloadEvent &&
        setState({
          isModalOpen: true,
          nextLocation: location,
        });
    }

    return isChange;
  };

   /* 重新整理事件 */
  const onBeforeunloadHandler = (e) => {
    const isBeforeunloadEvent = true;
    if(isFormChange(isBeforeunloadEvent, {})) e.returnValue = '確定離開此頁嗎？';
  };
  
  /* 加入重新整理事件 */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeunloadHandler);
    return()=>{
      window.removeEventListener('beforeunload', onBeforeunloadHandler);
    };
  }, []);

  useEffect(() => {
    if (!resetTrigger) return;
    let newSchema = {};
    Object.entries(schema).forEach(([key, item], index) => {
      newSchema[key] = item.elementProps.defaultValue ? item.elementProps.defaultValue : '';
    });
    reset(newSchema);
  }, [resetTrigger]);
  useEffect(() => {
    onFormChange(watchAllFields);
  }, [watchAllFields]);

  /* Modal放棄變更後，網頁跳轉 */
  useEffect(()=>{
    isModalCancelClick && history.push(nextLocation.pathname);
  }, [isModalCancelClick]);

  /* 處理網頁做任何導向前的彈出視窗 */
  useEffect(() => {
    const isBeforeunloadEvent = false;

    // 點選sidebar彈出視窗
    const unblock = history.block((location) => {
      if(isFormChange(isBeforeunloadEvent, location)) return false;
      else return true;
    });

    return () => {
      unblock();
    };
  },[isModalCancelClick]);

  return (
    <UiForm onSubmit={handleSubmit(onSubmit)}>
      <BsModal
        name={'prompt'}
        open={isModalOpen}
        title={'是否儲存設定變更？'}
        isFull={true}
        isLoading={isLoading}
        okText={'儲存變更'}
        cancelText={'放棄變更'}
        onOk={onOkHandler}
        onCancel={onCancelHandler}
        uiBsModalPadding={'48px 60px 20px'}>
          儲存後新設定將覆蓋原有設定。確認儲存請按「儲存變更」。
      </BsModal>
      {
        title && <UiFormTitle>{title}</UiFormTitle>
      }
      {
        Object.entries(schema).map(([key, item], index) => {
          if (blackList.includes(key)) return null;
          const { component, elementProps, rules, style } = item;
          const nextLabel = rules && rules['required'] ? `${elementProps.label}*` : elementProps.label;
          const isError = errors[key] && errors[key].message ? true : false;
          const nextHelperText = isError ? errors[key].message : elementProps.helperText || '';
          return (
            component ? (
              <>
                {needHide ?
                  <>
                    {!elementProps.needHide &&
                      <UiControllerWrapper key={key} css={style}>
                        <Controller
                          error={isError}
                          as={component}
                          name={key}
                          control={control}
                          rules={rules}
                          fullWidth={true}
                          {...elementProps}
                          label={nextLabel}
                          helperText={nextHelperText}
                        />
                      </UiControllerWrapper>}
                  </> :
                  <UiControllerWrapper key={key} css={style}>
                    <Controller
                      error={isError}
                      as={component}
                      name={key}
                      control={control}
                      rules={rules}
                      fullWidth={true}
                      {...elementProps}
                      label={nextLabel}
                      helperText={nextHelperText}
                    />
                  </UiControllerWrapper>
                }
              </>
            ) : (
                <Fragment key={key}>
                  {
                    Children.toArray(children).filter(el => el.props.name === key).map(el => {
                      const nextHelperText = isError ? errors[key].message : el.props.helperText || '';
                      return (
                        <UiControllerWrapper key={key}>
                          <Controller
                            error={isError}
                            as={el}
                            name={key}
                            control={control}
                            rules={rules}
                            fullWidth={true}
                            {...elementProps}
                            {...el.props}
                            label={nextLabel}
                            helperText={nextHelperText}
                          />
                        </UiControllerWrapper>
                      );
                    }
                    )
                  }
                </Fragment>
              )
          );
        })
      }


      <UiButtonWrapper>
        {
          buttons && buttons.map((button, index) => {
            return (
              <Button
                onClick={() => formHandler(button.func)}
                key={button.text}
                buttonColor={button.color}
                autoFocus={index === 0 && true}
                type={button.type}
                loading={isLoading}
                icon={button.icon}
                disabled={button.disabled}
              >
                {button.text}
              </Button>
            );
          })
        }
      </UiButtonWrapper>
    </UiForm >
  );
};

Form.propTypes = {
  title: PropTypes.string,
  ref: PropTypes.object,
  schema: PropTypes.shape({
    component: PropTypes.element,
    elementProps: PropTypes.object,
    rules: PropTypes.shape({
      required: PropTypes.shape({
        value: PropTypes.bool,
        message: PropTypes.string,
      }),
      maxLength: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      minLength: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      max: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      min: PropTypes.shape({
        value: PropTypes.number,
        message: PropTypes.string,
      }),
      pattern: PropTypes.shape({
        value: PropTypes.instanceOf(RegExp),
        message: PropTypes.string,
      }),
      validate: PropTypes.func
    })
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onFormChange: PropTypes.func,
  buttons: PropTypes.array,
  children: PropTypes.node,
  resetTrigger: PropTypes.bool,
  blackList: PropTypes.array,
  needHide: PropTypes.bool,
  prevFormValue: PropTypes.object,
};

