import React from 'react';
import PropTypes from 'prop-types';

import { ClickableUpload, Button, Icon } from 'components';

import { UiUploadButtonLabel } from './UploadButton.style';


/**
 * 上傳檔案按鈕
 */

export const UploadButton = ({
  label,
  fileUrl,
  onChange,
  filesLimit = 1
}) => {

  return (
    <ClickableUpload filesLimit={filesLimit} onUploaded={onChange} fileUrl={fileUrl} isShowFileList={true}>
      <Button>
        <Icon name="upload" size="small" icolor="inherit" />
        <UiUploadButtonLabel>{label}</UiUploadButtonLabel>
      </Button>
    </ClickableUpload>
  );
};

UploadButton.propTypes = {
  label: PropTypes.string,
  fileUrl: PropTypes.string,
  onChange: PropTypes.func,
  filesLimit: PropTypes.number
};


