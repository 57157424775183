import React from 'react';

import { SingleLayout, CustomerService } from 'components';


/**
 * 註冊/忘記密碼頁面
 */

export const CustomerServicePage = props => {
  return (
    <SingleLayout>
      <CustomerService {...props} />
    </SingleLayout>
  );
};



