import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  PictureBox,
  ClickablePopoverMenu,
  Icon,
  Modal
} from 'components';
import {
  UiAlbumCard,
  UiLine,
  UiAlbumContent,
  UiAlbumTitle,
  UiAlbumFooter,
  UiAlbumFooterContent,
  UiAlbumPicture,
} from './AlbumCard.style';
import { useSetState } from 'utils/hooks/useSetState';
import { useParams, useHistory } from 'react-router-dom';
import { TextField as MatTextField } from '@material-ui/core';

/**
 * 相簿列表卡牌
 */
const EDIT_STATUS = {
  ALIGN_TOP: 'verticalAlignTop',
  DELETE: 'delete',
  EDIT: 'edit'
};

export const AlbumCard = ({
  data = {},
  canEdit = true,
  canDelete = true,
  updateAlbum = () => { },
  deleteAlbum = () => { },
  checkIsRepeatName = () => { },
  getEditAlbumModalState = () => { },
  type = 'image',
}) => {
  const { organizationId, classId } = useParams();
  const history = useHistory();
  const { id, path, name, totalEntryCount } = data;
  const [
    {
      isModalOpen,
      editStatus,
      isCanSend,
    },
    setState] = useSetState({
      isModalOpen: false,
      editStatus: EDIT_STATUS.READ,
      isCanSend: true,
    });


  //修改成編輯or刪除模式
  const setEditStatus = status => () => setState({ editStatus: status, isModalOpen: status !== EDIT_STATUS.EDIT });

  //修改成閱讀模式
  const setEditStatusToRead = status => () => setState({ editStatus: status });
  const popoverMenuList = [
    {
      label: '取消',
      func: setEditStatusToRead(EDIT_STATUS.READ),
      iconName: 'close',
      isShow: editStatus === EDIT_STATUS.EDIT
    },
    {
      label: '重新命名',
      func: () => { getEditAlbumModalState(true, name, id); },
      iconName: 'edit',
      isShow: canEdit
    },
    { label: '刪除', func: setEditStatus(EDIT_STATUS.DELETE), iconName: 'delete', isShow: canDelete },
  ];
  const deleteModalConfig = {
    text: '是否要將此相簿刪除？',
    confirmAction: () => deleteAlbum(data, id)
  };
  const ModalConfig = editStatus === EDIT_STATUS.DELETE ? deleteModalConfig : '';
  const buttons = [
    {
      text: '確認',
      func: ModalConfig.confirmAction
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const getModalStateHandler = state => {
    setState({ isModalOpen: state });
  };

  const categoryText = type => {
    switch (type) {
      case 'image':
        return (
          <>
            <span>圖片</span>
            <span>{(totalEntryCount || 0) + '張'}</span>
          </>
        );
      case 'video':
        return (
          <>
            <span>影片</span>
            <span>{(totalEntryCount || 0) + '片'}</span>
          </>
        );
      case 'profile':
        return (
          <>
            <span>圖片</span>
            <span>{(totalEntryCount || 0) + '張'}</span>
          </>
        );
      default:
    }
  };

  //跳轉相片列表
  const goToPictureList = (id) => {
    history.push(`/home${organizationId ? `/${organizationId}` : ''}/class/${classId}/album/${id}`);
  };

  const editReplieMessageHandler = async (newName, id) => {
    if (newName === name || checkIsRepeatName(newName)) {
      setEditStatusToRead(EDIT_STATUS.READ)();
      return;
    }
    if (isCanSend) {
      updateAlbum(newName, id);
      setTimeout(() => {
        setState({
          isCanSend: true
        });
      }, 2000);
      setState({
        isCanSend: false
      });
      setEditStatusToRead(EDIT_STATUS.READ)();
    }
  };

  return (
    <UiAlbumCard>
      <Card>
        <UiAlbumPicture onClick={() => { goToPictureList(id); }} >
          <PictureBox path={path}></PictureBox>
        </UiAlbumPicture>

        <UiLine></UiLine>
        <UiAlbumContent>
          {
            editStatus !== EDIT_STATUS.EDIT ?
              <UiAlbumTitle>{name}</UiAlbumTitle>
              :
              <MatTextField
                defaultValue={name}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    editReplieMessageHandler(ev.target.value, id);
                  }
                }}
              />
          }

          <UiAlbumFooter>
            <UiAlbumFooterContent>
              {
                categoryText(type)
              }
            </UiAlbumFooterContent>
            {!data.category && (canDelete || canEdit) &&
              <ClickablePopoverMenu menuList={popoverMenuList}>
                <Icon haveBg={false} name="moreHoriz" icolor="#000"></Icon>
              </ClickablePopoverMenu>
            }

          </UiAlbumFooter>
        </UiAlbumContent>
        <Modal
          isOpen={isModalOpen}
          text={ModalConfig.text}
          buttons={buttons}
          getModalState={getModalStateHandler}
        />
      </Card>
    </UiAlbumCard>
  );
};

AlbumCard.propTypes = {
  data: PropTypes.shape({

  }),
  canDelete: PropTypes.bool,
  canEdit: PropTypes.bool,
  onDelete: PropTypes.func,
  updateAlbum: PropTypes.func,
  checkIsRepeatName: PropTypes.func,
  deleteAlbum: PropTypes.func,
  getEditAlbumModalState: PropTypes.func,
  type: PropTypes.string,
};


