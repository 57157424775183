import React, { useEffect, useCallback, useRef, } from 'react';
import { useHistory, useParams, } from 'react-router-dom';
import { useFirebaseStorage } from 'utils/hooks/useFirebaseStorage';
import { TextField as MatTextField } from '@material-ui/core';
import { Select, PhoneForm, Form, Button, Modal,AvatarUpload } from 'components';
import { useCustomer } from 'store/customer';
import { useUser } from 'store/user';
import { useSetState } from 'utils/hooks/useSetState';
import { emailReg, phoneNumberValid } from 'utils/validation';
import { EDIT_STATUS, ORGANIZATION_SETTINGS_VALUE } from 'constants/index';
import { useAlert } from 'utils/hooks/useAlert';
import { 
  UiCustomerForm,
  UiCustomerButtonBox,
  UiFormBox,
  UiFormText,
} from './CustomerForm.style';

/**
 * 邀請學生Form
 */

const CUSTOMER_KEY = {
  USERID: 'userId',
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber'
};



export const CustomerForm = ({ editStatus }) => {
  const prevFormValue = useRef({});
  const [{
    data,
    isLoading,
    selectOption,
    blackList,
    isCreating,
    modalState,
    imgUrl,
  }, setState] = useSetState({
    data: null,
    isLoading: editStatus === EDIT_STATUS.CREATE ? false : true,
    selectOption: CUSTOMER_KEY.USERID,
    blackList: [],
    isCreating: editStatus === EDIT_STATUS.CREATE,
    modalState: false,
    imgUrl:'',
  });
  const history = useHistory();
  const { upload } = useFirebaseStorage();
  const { setAlert } = useAlert();
  const { organizationId, customerId } = useParams();
  const [{myOrganization}, { changeUserRole }] = useUser();
  const [
    { customer },
    { inviteCustomers, getCustomer, updateCustomer }
  ] = useCustomer();

  const schema = {
    [CUSTOMER_KEY.USERID]: {
      component: MatTextField,
      elementProps: {
        label: '學生 id',
        value: '',
        helperText: ''
      },
    },
    [CUSTOMER_KEY.EMAIL]: {
      component: MatTextField,
      elementProps: {
        label: 'email',
        value: '',
        helperText: '',
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        pattern: {
          value: emailReg,
          message: 'mail 格式錯誤',
        }
      }
    },
    [CUSTOMER_KEY.MOBILE_NUMBER]: {
      component: PhoneForm,
      elementProps: {
        label: '緊急聯絡電話',
        defaultPhoneNum: '',
        defaultCountryNum: '',
        helperText: '',
        onChange: n => {
          if (n[1]) {
            return (n[0] + n[1]);
          }
          return null;
        }
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        },
        validate: value => {
          if (!value) return;
          return phoneNumberValid(value) || '電話格式錯誤';
        }
      }
    },
    nickname: {
      component: MatTextField,
      elementProps: {
        label: '機構暱稱',
        value: '',
        helperText: ''
      },
    },
  };

  const buttons = [
    {
      text: '確認',
      type: 'submit'
    },
    {
      text: '回到上一頁',
      color: 'info',
      icon: 'exitToApp',
      func: () => { history.push(`/organization/${organizationId}/customer`); }
    }
  ];

  const selectProps = {
    label: '註冊方式',
    options: [
      {
        name: '學生 id',
        value: CUSTOMER_KEY.USERID
      },
      {
        name: '手機號碼',
        value: CUSTOMER_KEY.MOBILE_NUMBER
      },
      {
        name: 'email',
        value: CUSTOMER_KEY.EMAIL
      }
    ],
    submitHandler: value => {
      setState({ selectOption: value });
    }
  };

    // 上傳檔案
    const onUploaded = async file => {//編輯才有上傳圖片
      const enCodeCustomerId = encodeURIComponent(customerId).replace('.', '');
      const fileType = file.type.split('/')[1];
      const uploadPath = `${organizationId}/customerAvatars/${enCodeCustomerId}.${fileType}`;
      const { status, url } = await upload(uploadPath, file);

      if (status) {
        setState({ imgUrl: url });
        setAlert('上傳成功!', 'success');
      } else {
        setAlert('上傳失敗!', 'warning');
      }
    };


  const submitFormHandler = async data => {
    if (editStatus === EDIT_STATUS.READ) {
      history.push(`/organization/${organizationId}/customer`);
    }
    setState({ isLoading: true });
    let isSuccess;
    // 新增
    if (editStatus === EDIT_STATUS.CREATE) {
      const { nickname } = data;
      isSuccess = await inviteCustomers({
        customer:
          {
            [selectOption]: data[selectOption],
            nickname
          }
      });
    }

    // 編輯
    if (editStatus === EDIT_STATUS.EDIT) {
      let isValidNumber = false;
      let isValidEmail = false;

      if (data.mobileNumber) {
        isValidNumber = phoneNumberValid('+' + data.mobileNumber);
        if (!isValidNumber) {
          setAlert('電話格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      }

      if (data.email) {
        isValidEmail = emailReg.test(data.email);
        if (!isValidEmail) {
          setAlert('mail格式錯誤', 'error');
          setState({ isLoading: false });
          return;
        }
      } else {
        delete data.email;
      }
      const formData = {};
      if (data.email) formData.email = data.email;
      if (data.mobileNumber) formData.mobileNumber = data.mobileNumber;
      if (data.nickname) formData.nickname = data.nickname;
      const nextData = data && Object.assign(formData, { thumbnailUrl: imgUrl });
      isSuccess = await updateCustomer(nextData);
    }

    setState({ isLoading: false });
    if (isSuccess) history.push(`/organization/${organizationId}/customer`);
  };

  useEffect(() => {
    let nextBlackList = [];

    if (!isCreating) {
      setState({ blackList: nextBlackList });
      return;
    }

    switch (selectOption) {
      case CUSTOMER_KEY.USERID:
        nextBlackList = [CUSTOMER_KEY.EMAIL, CUSTOMER_KEY.MOBILE_NUMBER];
        break;
      case CUSTOMER_KEY.EMAIL:
        nextBlackList = [CUSTOMER_KEY.USERID, CUSTOMER_KEY.MOBILE_NUMBER];
        break;
      case CUSTOMER_KEY.MOBILE_NUMBER:
        nextBlackList = [CUSTOMER_KEY.USERID, CUSTOMER_KEY.EMAIL];
        break;
      default:
        break;
    }

    setState({ blackList: nextBlackList });
  }, [selectOption]);

  /* 邀請學生，設定比對資料用的 prev  */
  const setPrevFormValueToCreate = () => {
    // 清空 prevFormValue
    for(const key in prevFormValue.current) delete prevFormValue.current[key];

    // 設定 prevFormValue
    prevFormValue.current['nickname'] = null;
    switch (selectOption){
      case CUSTOMER_KEY.USERID:
        prevFormValue.current[CUSTOMER_KEY.USERID] = null;
        break;
      case CUSTOMER_KEY.MOBILE_NUMBER:
        prevFormValue.current[CUSTOMER_KEY.MOBILE_NUMBER] = null;
        break;
      case CUSTOMER_KEY.EMAIL:
        prevFormValue.current[CUSTOMER_KEY.EMAIL] = null;
        break;
      default:
        break;
    }
  };

  const nextData = useCallback(() => {
    // 不可被編輯的欄位
    const blackList = [CUSTOMER_KEY.USERID,'originalName','originalNickname'];
    let isRead = false;
    // 根據網址狀態判斷
    switch (editStatus) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!customer) return;
        break;
      // 檢視
      case EDIT_STATUS.READ:
        isRead = true;
        if (!customer) return;
        break;
      case EDIT_STATUS.CREATE:
      default:
        setPrevFormValueToCreate(); // prev 比對資料用初始值設
        return schema;
    }

    // schema 比對
    const newData = Object.assign({}, schema);
    Object.entries(newData).forEach(([key]) => {
      
      if (!customer['email']) {
        delete newData['email'].rules;
      }

      if (!customer['mobileNumber']) {
        delete newData['mobileNumber'].rules;
      }

      if (key === CUSTOMER_KEY.USERID) {
        newData[key].elementProps.defaultValue = customer['id'];
        prevFormValue.current[key] = customer['id'];
      } else {
        newData[key].elementProps.defaultValue = customer[key];
        prevFormValue.current[key] = customer[key] ?? null;
      }

      if (editStatus === EDIT_STATUS.READ) {
        newData[key].elementProps.disabled = isRead;
      } else {
        newData[key].elementProps.disabled = blackList.includes(key) ? !isRead : isRead;
      }
    });
    
    return newData;
  }, [data, editStatus, schema, customer]);

  //Modal buttons
  const modalButtons = [
    {
      text: '確認',
      func: async () => {
        await changeUserRole().then(isSuccess => {
          isSuccess && history.push(`/organization/${organizationId}/teachers`);
        });
      }
    },
    {
      text: '取消',
      color: 'highlight',
      func: () => { }
    }
  ];

  const openModal = () => {
    setState({
      modalState: true
    });
  };

  const getModalStateHandler = state => {
    setState({
      modalState: state
    });
  };

  const fetchData = async () => {
    const res = await getCustomer();
    setState({ isLoading: false,imgUrl: res?.thumbnailUrl });
  };

  useEffect(() => {
    if (!customerId) return;
    fetchData();
  }, [customerId]);

  return (
    <UiCustomerForm>
      <UiCustomerButtonBox>
        <Button onClick={openModal}>修改權限為教師</Button>
      </UiCustomerButtonBox>
      {
        isCreating && (
          <Select
            label={selectProps.label}
            value={selectOption}
            options={selectProps.options}
            submitHandler={selectProps.submitHandler}
          />
        )
      }
      {(editStatus !== EDIT_STATUS.CREATE) &&
        <AvatarUpload
          imgUrl={imgUrl}
          onUploaded={onUploaded}
          disabled={
            myOrganization.organization.customerProfileOriginSetting === ORGANIZATION_SETTINGS_VALUE.DEFAULT
            || editStatus === EDIT_STATUS.READ
          }
        />
      }
      <UiFormBox>
        {
          editStatus !== EDIT_STATUS.CREATE && <>
            <UiFormText>姓名: {customer?.originalName}</UiFormText>
            <UiFormText>暱稱: {customer?.originalNickname}</UiFormText>
          </>
        }
        <Form
          schema={!isLoading ? nextData() : {}}
          isLoading={isLoading}
          buttons={buttons}
          blackList={blackList}
          onSubmit={submitFormHandler}
          prevFormValue={prevFormValue}
          editStatus={editStatus}
        />
      </UiFormBox>
      <Modal
        isOpen={modalState}
        text="確定要修改此使用者權限為教師"
        buttons={modalButtons}
        getModalState={getModalStateHandler}
      />
    </UiCustomerForm>
  );
};


