import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import getTime from 'date-fns/getTime';
import { TextField as MatTextField } from '@material-ui/core';
import { useSetState } from 'utils/hooks/useSetState';
import { Card, Form, DateTimePicker, UploadButton, Checkbox } from 'components';
import { UiCardHeader } from './HomeworkCardEdit.style';
import { EDIT_STATUS } from 'constants/index';

/**
 * 編輯作業卡牌
 */

export const HomeworkCardEdit = ({
  data,
  type = '',
  title = '',
  isLoading = false,
  isOpen,
  onSubmit = () => { },
  changeCardState = () => { },
}) => {
  const [state, setOpen] = useState(true);
  const [{ dueAt, publishedAt }, setState] = useSetState(
    {
      dueAt: '',
      publishedAt: '',
    });
  const modalStateHandler = () => {
    changeCardState();
    setOpen(!isOpen);
  };
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const buttons = [
    {
      text: '取消',
      color: 'highlight',
      func: () => { modalStateHandler(); }
    },
    {
      text: '確定',
      type: 'submit'
    }
  ];

  const schema = {
    title: {
      component: MatTextField,
      elementProps: {
        label: '作業標題',
        value: '',
        helperText: '',
        variant: 'outlined'
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    description: {
      component: MatTextField,
      elementProps: {
        label: '作業內容',
        value: '',
        helperText: '',
        variant: 'outlined',
        multiline: true,
        rows: 3
      },
      rules: {
        required: {
          value: true,
          message: '本欄位為必填'
        }
      }
    },
    dueAt: {
      component: DateTimePicker,
      elementProps: {
        label: '截止日期',
        value: '',
        helperText: '',
        defaultValue: null,
        minDate: publishedAt || new Date(),
        fullWidth: false,
        onChange: n => {
          setState({
            dueAt: getTime(n[0])
          });
          return n[0];
        }
      }
    },
    publishedAt: {
      component: DateTimePicker,
      elementProps: {
        label: '預約發佈',
        value: '',
        helperText: '',
        defaultValue: null,
        minDate: new Date(),
        maxDate: dueAt,
        fullWidth: false,
        onChange: n => {
          setState({
            publishedAt: getTime(n[0])
          });
          return n[0];
        }
      }
    },
    file: {
      component: UploadButton,
      elementProps: {
        label: '附加檔案',
        value: '',
        helperText: ''
      }
    },
    isLateSubmissionAllowed: {
      component: Checkbox,
      elementProps: {
        label: '允許遲交',
        checkedValue: true
      }
    }
  };

  const nextData = useCallback(() => {
    // 根據傳入type判斷
    switch (type) {
      // 編輯
      case EDIT_STATUS.EDIT:
        if (!data) return;
        break;
      // 建立 & 預設
      case EDIT_STATUS.CREATE:
      default:
        return schema;
    }
    // schema 比對
    let newData = Object.assign({}, schema);
    Object.entries(newData).forEach(([key]) => {
      // set data
      if (key === 'dueAt' && !data['dueAt']) {
        newData[key].elementProps.defaultValue = '';
      } else if (key === 'file') {
        newData[key].elementProps.defaultValue = data['attachmentUrl'];
        newData[key].elementProps.fileUrl = data['attachmentUrl'];
      } else {
        newData[key].elementProps.defaultValue = data[key];
      }
    });
    return newData;
  }, [publishedAt, dueAt]);

  return (
    <>
      {
        state &&
        <Card header={<UiCardHeader>{`To: ${title}`}</UiCardHeader>}>
          <Form schema={data ? nextData() : schema} buttons={buttons} isLoading={isLoading} onSubmit={onSubmit} />
        </Card>
      }
    </>

  );
};

HomeworkCardEdit.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    userName: PropTypes.string,
    createData: PropTypes.string,
    contentTitle: PropTypes.string,
    content: PropTypes.string,
    deadline: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number,
    dueAt: PropTypes.string,
    attachmentUrl: PropTypes.string,
  }),
  title: PropTypes.string,
  type: PropTypes.string,
  onSubmit: PropTypes.func,
  changeCardState: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool
};


