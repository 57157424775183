import styled from 'styled-components';

export const UiAlbumSlider = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	overflow-x: hidden;
	padding: 1rem 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 999;

	& > div {
		margin: auto;
		width: 100%;
		max-width: 680px;
	}

	& ::-webkit-scrollbar {
		width: 1px;
		height: 1px;
		background-color: #f5f5f5;
	}

	/* 定义滚动条轨道
  内阴影+圆角 */
	& ::-webkit-scrollbar-track {
		background-color: #f5f5f5;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	/* 定义滑块
  内阴影+圆角 */
	& ::-webkit-scrollbar-thumb {
		background-color: #555;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
`;

export const UiAlbumContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const UiClose = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;

	svg {
		font-size: 2rem;
		color: #fff;
		opacity: 0.6;
		transition: 0.5s;
	}

	&:hover {
		svg {
			opacity: 1;
		}
	}
`;

export const UiAlbumTop = styled.div`
	position: relative;
	overflow: hidden;
	padding: 2rem 0.5rem;
	width: 100%;
	min-height: 450px;
	background-color: rgba(0, 0, 0, 0.5);
`;

export const UiAlbumItem = styled.div`
	overflow: hidden;
	padding: 0 1rem;
	min-height: 385px;
	max-height: 100%;
	font-size: 0;

	div {
		position: relative;
		margin: auto;
		padding-top: 67%;
		width: 100%;
		height: 0;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			object-fit: contain;
		}

		video {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
		}
	}
`;

export const UiAlbumButtom = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	flex: 1;
	flex-direction: column;
`;


export const UiAlbumPost = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
	background-color: ${({ theme }) => theme.palette.background.main};
`;

export const UiAlbumUser = styled.div`
	display: flex;
	margin-right: 10px;
`;


export const UiAlbumUserInfo = styled.div`
	margin: 0 5px;

	div:nth-child(1) {
		padding-bottom: 5px;
		font-size: 1rem;
		font-weight: bold;
		color: ${({ theme }) => theme.palette.text.normal};
	}

	div:nth-child(2) {
		font-size: 0.75rem;
		color: ${({ theme }) => theme.palette.text.sub};
	}
`;

export const UiAlbumPostContent = styled.div`
	padding: 1rem 0;
	width: 100%;
`;

export const UiPostThumbUp = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 1px 2px 2px;
	min-width: 40px;
	color: #8d949e;
	background: #fff;
	border-radius: 15px;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 10px;

	svg {
		margin-right: 5px;
		font-size: 1.2rem;
	}
`;

export const UiAlbumPostActionBox = styled.div`
	display: flex;
	padding: 0.5rem 0;
	width: 100%;
	border-top: 1px solid #aaa;
`;

export const UiAlbumPostActionItem = styled.div`
	display: flex;
	align-items: center;
	font-size: 1rem;
	color: ${({ isLiked, theme }) => isLiked ? theme.palette.user.block.bar : '#585858'};
	transition: 0.5s;
	cursor: pointer;

	svg {
		margin-right: 5px;
		font-size: 1.5rem;
		color: ${({ isLiked, theme }) => isLiked ? theme.palette.user.block.bar : '#585858'};
		transition: 0.5s;
	}

	&:hover {
		color: ${({ theme }) => theme.palette.user.block.bar};
	}

	&:hover svg {
		color: ${({ theme }) => theme.palette.user.block.bar};
	}

`;

export const UiAlbumReplieBox = styled.div`
	width: 100%;
`;

export const UiAlbumReplieItem = styled.div`
	display: flex;
	padding: 10px;
	width: 100%;

	& + div {
		border-top: 1px solid #aaa;
	}
`;

export const UiAlbumReplieContent = styled.div`
	& > div:nth-child(1) {
		position: relative;
		padding: 8px 10px;
		font-size: 1rem;
		background: #eee;
		border-radius: 18px;
		letter-spacing: 2px;

		span {
			padding-right: 5px;
			font-size: 1rem;
			font-weight: bold;
			color: ${({ theme }) => theme.palette.text.normal};
			letter-spacing: initial;
		}

		img {
			display: block;
			margin-top: 10px;
			width: 100px;
			height: 100px;
		}
	}
`;

export const UiAlbumReplieAction = styled.div`
	margin-top: 5px;
	padding: 0 10px;

	span {
		font-weight: normal;
		cursor: pointer;
		transition: 0.5s;

		&:hover {
			text-decoration: underline;
		}
	}
`;

export const UiThumbUp = styled.div`
	position: absolute;
	bottom: -15px;
	right: -10px;
	display: flex;
	align-items: center;
	padding: 2px 1px 2px 2px;
	min-width: 40px;
	color: #8d949e;
	background: #fff;
	border-radius: 15px;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

	svg {
		margin-right: 5px;
		font-size: 1.2rem;
	}
`;

export const UiAlbumInput = styled.div`
	border-top: 1px solid #aaa;
	padding: 1rem;
`;

export const UiShowAllMessage = styled.div`
	padding: 1rem;
	text-align: center;

	span {
		color: ${({ theme }) => theme.palette.text.normal};
		cursor: pointer;
		transition: 0.5s;

		&:hover {
			color: ${({ theme }) => theme.palette.text.active};
		}
	}

`;

